import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
  CaretLeftOutlined,
  FileExcelOutlined, InfoCircleOutlined
} from "@ant-design/icons";
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { MenuOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Typography,
  Checkbox,
  Card,
  Col,
  Row,
  Radio,
  Collapse,
  Spin,
  message
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  DeleteAPIRequest,
  GetAPIRequest,
  PostAPIRequest,
} from "../../services";
import AddBrand from "../AddBrand/AddBrand";
import { Menu, Layout } from "antd";
import { useParams, useHistory } from "react-router-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import calquence from "../../assets/img/calquence.png";
import enhertu from "../../assets/img/enhertu.png";
import imfinzi from "../../assets/img/imfinzi.png";
import tagrisso from "../../assets/img/tagrisso.png";
import lynparza from "../../assets/img/lynparza.png";
import { CSVLink, CSVDownload } from "react-csv";
import US from '../../assets/img/flag-of-United-States-of-America.png';
import UK from '../../assets/img/flag-of-United-Kingdom.png';
import CHINA from '../../assets/img/flag-of-China.png';
import JAPAN from '../../assets/img/flag-of-Japan.png';
import GERMANY from '../../assets/img/flag-of-Germany.png';
import INDIA from '../../assets/img/flag-of-India.png';
import SPAIN from '../../assets/img/flag-of-Spain.png';
import NETHERLANDS from '../../assets/img/flag-of-Netherlands.png';
import RUSSIA from '../../assets/img/flag-of-Russia.png';
import ITALY from '../../assets/img/flag-of-Italy.png';
import CANADA from '../../assets/img/flag-of-Canada.png';
import FRANCE from '../../assets/img/flag-of-France.png';
import TableView from "../Admin/TableView";
import { useRef } from "react";


am4core.useTheme(am4themes_animated);
const CHART_ID = "timelineViewChart";
const { Header, Content, Sider } = Layout;

const { SubMenu } = Menu;
const { TabPane } = Tabs;

const Home = (props, location) => {
  const history = useHistory();
  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [releaseData, setReleaseData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({});
  const [tab, setTab] = useState([]);
  /*Filter objects*/
  const [regionFilter, setRegionFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);
  const [studyFilter, setStudyFilter] = useState([]);
  const [indicationFilter, setIndicationFilter] = useState([]);
  const [yearFilter, setYearFilter] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [years, setYears] = useState([]);
  const [tumors, setTumors] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  /*end*/
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const brandLogos = {
    calquence: calquence,
    enhertu: enhertu,
    imfinzi: imfinzi,
    tagrisso: tagrisso,
    lynparza: lynparza,
  };
  let { name, brandname, year, tumorid, tumorname, rdfid, rdfidname } = useParams();
   

  var eventSeries = null;
  var chart = {};

  useEffect(() => {
    getBranhdsList();
    getAllFilters();
    //  getBrandByName();
    return () => {
      // chart.dispose();
    }
  }, []);

  function useSizeHeight() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handelResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handelResize);
      return () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
    }, []);
    return size;
  }
  const [height, width] = useSizeHeight();

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  /* All api calls */

  function getAllFilters() {
    var url = `/launchtracker/api/v1/master/lookup/?type=tumor&type=years&type=country&type=region`;
    GetAPIRequest({
      url: url,
    }).then((response) => {
      response.length > 0 &&
        response.map((item, index) => {
          if (item.type == "tumor") setTumors(response[index].data);
          else if (item.type == "years") setYears(response[index].data);
          else if (item.type == "country") setCountries(response[index].data);
          else if (item.type == "region") setRegions(response[index].data);
        });
    });
  }

  const [isUser, setIsUser] = useState(false);
  function getBranhdsList() {
    var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
    GetAPIRequest({
      url: url,
    }).then((response) => {
      if (response) {
        const _response = response.sort(GetSortOrder("brand"));
        console.log("_response", _response);
        setBrands(_response);
        setTab(_response[0].brand);
        let isUser = response &&
          response.filter(x => x.is_admin) && response.filter(x => x.is_admin).length > 0 ? true : false;
        setIsUser(isUser)
      }
    });
  }
  function getBrandBackgroundColor(brand) {
    switch (brand) {
      case "CALQUENCE":
        return "#ffffff";

      case "ENHERTU":
        return "#000000";

      case "IMFINZI":
        return "#ffffff";

      case "LYNPARZA":
        return "#ffffff";

      case "TAGRISSO":
        return "#ffffff";

      case "ADAVODERTIB":
        return "#ffffff";

      case "CAMIZESTRANT":
        return "#000000";

      case "CAPIVASERTIB":
        return "#ffffff";

      case "DATO-DxD":
        return "#ffffff";

      case "MONALIZUMAB":
        return "#000000";

      case "SAVOLITNIB":
        return "#ffffff";
    }
  }

  function getBrandColor(brand, commercial_launch) {
    let pdate = commercial_launch;
    var todayDate = new Date().toISOString().slice(0, 10);
    if (todayDate < pdate) {
      switch (brand) {
        case "CALQUENCE":
          return "rgba(204, 51, 0, 0.5)";
        //#cc3300
        case "ENHERTU":
          return "rgba(237, 125, 49, 0.5)";
        //#ed7d31
        case "IMFINZI":
          return "rgba(192, 0, 0, 0.5)";
        //#c00000
        case "LYNPARZA":
          return "rgba(32, 55, 100, 0.5)";
        //#203764
        case "TAGRISSO":
          return "rgba(0, 153, 153, 0.5)";
        //#009999
        case "ADAVODERTIB":
          return "rgba(153, 51, 102, 0.5)";
        //#993366
        case "CAMIZESTRANT":
          return "rgba(102, 153, 255, 0.5)";
        //#6699ff 
        case "CAPIVASERTIB":
          return "rgba(51, 63, 79, 0.5)";
        //#333f4f
        case "DATO-DXD":
          return "rgba(0, 153, 0, 0.5)";
        //#009900
        case "MONALIZUMAB":
          return "rgba(230, 230, 230, 0.5)";
        //#e6e6e6 
        case "SAVOLITNIB":
          return " rgba(51, 51, 204, 0.5)";
        //#3333cc
      }
    }
    else {
      return "rgba(128,128,128, 0.5)"
    }
  }

  function getCountryIcon(country) {
    switch (country) {
      case "USA":
        return US;
      case "UK":
        return UK;
      case "CHINA":
        return CHINA;
      case "JAPAN":
        return JAPAN;
      case "GERMANY":
        return GERMANY;
      case "FRANCE":
        return FRANCE;
      case "INDIA":
        return INDIA;
      case "SPAIN":
        return SPAIN;
      case "Netherlands":
        return NETHERLANDS;
      case "RUSSIA":
        return RUSSIA;
      case "ITALY":
        return ITALY;
      case "CANADA":
        return CANADA;
    }
  }


  function getBrandByName() {
    var url = "";
    if (name && name != "undefined")
      url = `/launchtracker/api/v1/data/filter?brandId=${name}`;
    else if (tumorid && tumorid != "undefined")
      url = `/launchtracker/api/v1/data/filter?tumorId=${tumorid}`;
    else if (rdfidname && rdfidname != "undefined")
      url = `/launchtracker/api/v1/data/filter?study=${rdfidname}`;
    else
      url = `/launchtracker/api/v1/data/filter?year=${year}`;

    GetAPIRequest({
      url: url,
    }).then((response) => {
      const results = sortByCommercialDate(response);

      const uniqueProperties = [
        {
          uniqueCountries: [...new Set(results.map((item) => item.country))],
        },
        {
          uniqueRegion: [...new Set(results.map((item) => item.region))],
        },
        {
          uniqueIndication: [
            ...new Set(results.map((item) => item.indication)),
          ],
        },
        {
          uniqueStudy: [...new Set(results.map((item) => item.study))],
        },
        {
          uniqueYears: [
            ...new Set(results.map((item) => item.regulatory_approval)),
          ],
        },
      ];
      setFilters(uniqueProperties);

      setData(results);
      let realeseList = results.filter(x => x.commercial_launch != "");
      setReleaseData(realeseList);
      if (results.length > 0 && realeseList.length > 0)
        getTimeLineView(results);

      setCSVFileData(results);
    });
  }
  const setCSVFileData = (results) => {
    const csvData = results.map((row) => ({

      brand: row.brand,
      region: row.region,
      country: row.country,
      study: row.study,
      indication: row.indication,
      regulatory_approval: row.regulatory_approval,
      commercial_launch: row.commercial_launch,
      reimbursement_approval: row.reimbursement_approval
    }));
    setCSVData(csvData);
  };
  function sortByCommercialDate(response) {
    var currentdate = moment().format("YYYY-MM-DD");
    console.log(currentdate);
    const futureDateResponse = [];
    const pastDateResponse = [];
    response.map((row, index) => {
      row.brand_id = row.brand_id;
      row.key = row.data_id;
      row.data_id = row.data_id;
      row.regulatory_approval = row.regulatory_approval != null ? moment(row.regulatory_approval).format(
        "MMM-YYYY"
      ) : "";
      row.commercial_launch = row.commercial_launch != null ? moment(row.commercial_launch).format("MMM-YYYY") : "";
      row.reimbursement_approval = row.reimbursement_approval != null ? moment(row.reimbursement_approval).format(
        "MMM-YYYY"
      ) : "";
      if (moment(row.commercial_launch).isAfter(currentdate)) {
        futureDateResponse.push(row);
      }
      else {
        pastDateResponse.push(row)
      }
    });
    futureDateResponse.sort(function (a, b) {
      return a.commercial_launch < b.commercial_launch ? -1 : 0
    })


    return [...futureDateResponse, ...pastDateResponse];
  }

  function setFilters(uniqueProperties) {
    var countries = [];
    var region = [];
    var indication = [];
    var study = [];
    var year = [];
    uniqueProperties.map((item, index) => {
      if (Object.keys(item)[0] == "uniqueCountries") {
        item.uniqueCountries.map((item, index) => {
          const countrydata = {
            text: item,
            value: item,
          };
          countries.push(countrydata);
        });
      } else if (Object.keys(item)[0] == "uniqueRegion") {
        item.uniqueRegion.map((item, index) => {
          const regiondata = {
            text: item,
            value: item,
          };
          region.push(regiondata);
        });
      } else if (Object.keys(item)[0] == "uniqueIndication") {
        item.uniqueIndication.map((item, index) => {
          const indicationdata = {
            text: item,
            value: item,
          };
          indication.push(indicationdata);
        });
      } else if (Object.keys(item)[0] == "uniqueStudy") {
        item.uniqueStudy.map((item, index) => {
          const studydata = {
            text: item,
            value: item,
          };
          study.push(studydata);
        });
      } else if (Object.keys(item)[0] == "uniqueYears") {
        item.uniqueYears.map((item, index) => {
          const yeardata = {
            text: item,
            value: item,
          };
          year.push(yeardata);
        });
      }
      setCountryFilter(countries);
      setRegionFilter(region);
      setIndicationFilter(indication);
      setStudyFilter(study);
      setYearFilter(year);
    });
  }

  function updateBrand(row) {

    var url = "/launchtracker/api/v1/data/save";
    row.brand_id = brandId;
    PostAPIRequest({
      url: url,
      data: row,
    }).then((row) => {
      var responseObj = {
        key: row.data_id,
        id: row.data_id,
        brand: row.brand,
        region: row.region,
        country: row.country,
        study: row.study,
        indication: row.indication,
        regulatory_approval: row.regulatory_approval != null ? moment(row.regulatory_approval).format(
          "MMM-YYYY"
        ) : "",
        commercial_launch: row.commercial_launch != null ?
          moment(row.commercial_launch).format("MMM-YYYY") : "",
        reimbursement_approval: row.reimbursement_approval != null ? moment(row.reimbursement_approval).format(
          "MMM-YYYY"
        ) : "",
      }

      let objIndex = data.findIndex(obj => obj.data_id == row.data_id);
      data[objIndex] = responseObj;
      setData(data);
      callback(tab);
    });
  }
  function handleDelete(key) {
    var url = "/launchtracker/api/v1/data/id/" + key;
    DeleteAPIRequest({
      url: url,
    }).then((response) => {
      callback(tab);
    });
  }
  /* All api calls ends */
  const { Search } = Input;

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const [searchData, setSearchData] = useState([]);
  const onSearch = (event) => {

    if (event.target.value != "") {

      let filterData = data.filter((column) => {
        return (
          column.study
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          column.indication
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      });

      setSearchData((data) => filterData);
    } else {
      setSearchData([]);
      setData(data);
    }

    setIsSearch(true);
    setTimeout(() => {
      setIsSearch(false);
    }, 500);
    console.log(event.target.value);
  };
  const edit = (record) => {
    setBrandId(record.brand_id);
    var launchdate = moment(
      record.commercial_launch,
      "YYYY-MM-DD",
      true
    ).format();
    var regulatoryapprovalDate = moment(
      record.regulatory_approval,
      "YYYY-MM-DD",
      true
    ).format();
    var reimbursementapprovalDate = moment(
      record.reimbursement_approval,
      "YYYY-MM-DD",
      true
    ).format();
    if (launchdate != "Invalid date")
      record.commercial_launch = moment(launchdate);

    if (regulatoryapprovalDate != "Invalid date")
      record.regulatory_approval = moment(regulatoryapprovalDate);

    if (reimbursementapprovalDate != "Invalid date")
      record.reimbursement_approval = moment(reimbursementapprovalDate);

    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = (key) => {

    const editedRow = data.filter((x) => x.key == key)[0];
    if (editedRow) {
      editedRow.regulatory_approval = moment(editedRow.regulatory_approval).format("YYYY-MM-DD")
        == "Invalid date" ? "" : moment(editedRow.regulatory_approval).format("YYYY-MM-DD");;
      editedRow.commercial_launch = moment(editedRow.commercial_launch).format(
        "YYYY-MM-DD") == "Invalid date" ? "" : moment(editedRow.commercial_launch).format("YYYY-MM-DD");
      editedRow.reimbursement_approval = moment(
        editedRow.reimbursement_approval
      ).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(
        editedRow.reimbursement_approval
      ).format("YYYY-MM-DD");
    }

    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      row.data_id = key;
      updateBrand(row);
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  function onDateChange(date, dateString) {
    console.log(date, dateString);
  }
  const today = moment(new Date().getDate(), "DD/MM/YYYY");
  const dateFormat = "DD/MM/YYYY";
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    var inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    if (inputType === "date") {
      inputNode = <DatePicker format={"YYYY-MM-DD"} onChange={onDateChange} value="" />;
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: inputType === "date" ? false : true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  };

  const clearAll = () => {
    setCountryFilterArr('');
    setIndicationFilterArr('');
    setStudyFilterArr('');
    setRegionFilterArr('');
    setSortedInfo({});
    setFilteredInfo({});
    setSearchValue('');

    getBrandsByFilter();
    tableRef.current.clearAll();
  };

  const columns = [
    // {
    //   title: "Brand",
    //   dataIndex: "brand",
    //   inputType: "text",
    //   key: "brand",
    //   editable: true,
    //   filters: brands.map((item, index) => {
    //     return {
    //       text: item.brand,
    //       value: item.brand
    //     }
    //   }),
    //   filteredValue: filteredInfo.brand || null,
    //   onFilter: (value, record) => record.brand.includes(value),
    //   sorter: (a, b) => a.brand.length - b.brand.length,
    //   sortOrder: sortedInfo.columnKey === "brand" && sortedInfo.order,
    //   ellipsis: true,
    // },
    {
      title: "Region",
      dataIndex: "region",
      inputType: "text",
      key: "region",
      // editable: true,
      //  filters: regionFilter,
      filteredValue: filteredInfo.region || null,
      onFilter: (value, record) => record.region.includes(value),
      sorter: (a, b) => a.region.length - b.region.length,
      sortOrder: sortedInfo.columnKey === "region" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      inputType: "text",
      key: "country",
      //filters: countryFilter,
      filteredValue: filteredInfo.country || null,
      onFilter: (value, record) => record.country.includes(value),
      sorter: (a, b) => a.country.length - b.country.length,
      sortOrder: sortedInfo.columnKey === "country" && sortedInfo.order,
      //editable: true,
    },
    {
      title: "Study",
      dataIndex: "study",
      inputType: "text",
      key: "study",
      // filters: studyFilter,
      filteredValue: filteredInfo.study || null,
      onFilter: (value, record) => record.study.includes(value),
      sorter: (a, b) => a.study.length - b.study.length,
      sortOrder: sortedInfo.columnKey === "study" && sortedInfo.order,
      // editable: true,
    },
    {
      title: "Indication",
      dataIndex: "indication",
      inputType: "text",
      key: "indication",
      // filters: indicationFilter,
      filteredValue: filteredInfo.indication || null,
      onFilter: (value, record) => record.indication.includes(value),
      sorter: (a, b) => a.indication.length - b.indication.length,
      sortOrder: sortedInfo.columnKey === "indication" && sortedInfo.order,
      //editable: true,
    },
    {
      title: "Tumor Type",
      dataIndex: "tumor_name",
      inputType: "text",
      key: "tumor_name",
      // filters: indicationFilter,
      filteredValue: filteredInfo.indication || null,
      onFilter: (value, record) => record.tumor_name.includes(value),
      sorter: (a, b) => a.tumor_name.length - b.tumor_name.length,
      sortOrder: sortedInfo.columnKey === "tumor_name" && sortedInfo.order,
      //editable: true,
    },
    {
      title: "Regulatory Approval",
      dataIndex: "regulatory_approval",
      inputType: "date",
      key: "regulatory_approval",
      // filters: yearFilter,
      filteredValue: filteredInfo.regulatory_approval || null,
      onFilter: (value, record) => record.regulatory_approval.includes(value),
      sorter: (a, b) => a.regulatory_approval < b.regulatory_approval ? -1 :
        (a.regulatory_approval > b.regulatory_approval ? 1 : 0),
      sortOrder: sortedInfo.columnKey === "regulatory_approval" && sortedInfo.order,
      editable: true,
    },
    {
      title: "Commercial Launch",
      dataIndex: "commercial_launch",
      inputType: "date",
      key: "commercial_launch",
      filteredValue: filteredInfo.commercial_launch || null,
      onFilter: (value, record) => record.commercial_launch.includes(value),
      sorter: (a, b) => a.commercial_launch < b.commercial_launch ? -1 :
        (a.commercial_launch > b.commercial_launch ? 1 : 0),
      sortOrder: sortedInfo.columnKey === "commercial_launch" && sortedInfo.order,
      editable: true,
    },
    {
      title: "Reimbursement Approval",
      width: 150,
      dataIndex: "reimbursement_approval",
      inputType: "date",
      key: "reimbursement_approval",
      filteredValue: filteredInfo.commercial_launch || null,
      onFilter: (value, record) => record.reimbursement_approval.includes(value),
      sorter: (a, b) => a.reimbursement_approval < b.reimbursement_approval ? -1 :
        (a.reimbursement_approval > b.reimbursement_approval ? 1 : 0),
      sortOrder: sortedInfo.columnKey === "reimbursement_approval" && sortedInfo.order,
      editable: true,
    },
    {
      title: "",
      width: 80,
      dataIndex: "action",
      render: (_, record) => {
        var brandObj = brands.filter((x) => x.brand == record.brand)[0];
        var isAdmin = false;
        if (brandObj) {
          isAdmin = brandObj.is_admin == 1 ? true : false;
        }
        const editable = isEditing(record);
        return editable && isAdmin ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.key)}
            >
              <a className="cancel-btn atn-btn">financialcalendar-container</a>
            </Popconfirm>
          </span>
        ) : (
          <>

            <Space>
              <div className="edit-ctn">
                <Typography.Link
                  disabled={editingKey !== "" || !isAdmin}
                  onClick={() => edit(record)}
                >
                  <EditOutlined /> Edit
                </Typography.Link>
              </div>
            </Space>

          </>
        );
      }
    },
    {
      title: "",
      width: 50,
      dataIndex: "operation",
      render: (_, record) => {
        var brandObj = brands.filter((x) => x.brand == record.brand)[0];
        var isAdmin = false;
        if (brandObj) {
          isAdmin = brandObj.is_admin == 1 ? true : false;
        }
        return (
          <Popconfirm disabled={!isAdmin}
            title="Sure to delete?"
            onConfirm={() => {
              handleDelete(record.key);
            }}
          >
            <div className="delete-ctn">
              <DeleteOutlined />
            </div>
          </Popconfirm>
        )
      }
    },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  /* Tabs creation*/

  function callback(key) {
    key = key == undefined ? tab : key;
    var selectedBrand = brands.filter((x) => x.brand == key)[0];
    setBrand(selectedBrand);
    setEditingKey("");
    getBrandByName(key);
    setTab(key);
    console.log(key);
  }

  /* End */

  /*Filters*/
  let countryArr = [];
  let indicationArr = [];
  let regionArr = [];
  let studyArr = [];

  const [countryFilterArr, setCountryFilterArr] = useState("");
  const [indicationFilterArr, setIndicationFilterArr] = useState("");
  const [regionFilterArr, setRegionFilterArr] = useState("");
  const [studyFilterArr, setStudyFilterArr] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (countryFilterArr.length > 0 || regionFilterArr.length > 0 ||
      indicationFilterArr.length > 0 || studyFilterArr.length > 0)
      getBrandsByFilter();
    else
      getBrandsByFilter();
  }, [countryFilterArr, regionFilterArr, indicationFilterArr, studyFilterArr]);

  function filterByStudy(checkedValues) {
    setStudyFilterArr(checkedValues);
  }
  function filterByIndication(checkedValues) {
    setIndicationFilterArr(checkedValues);
  }
  function filterByCountry(checkedValues) {
    setCountryFilterArr(checkedValues);
  }
  function filterByRegion(checkedValues) {
    setRegionFilterArr(checkedValues);
  }

  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  function getBrandsByFilter() {

    setIsLoading(true)

    var url = "";
    if (name && name != "undefined")
      url = `/launchtracker/api/v1/data/filter?brandId=${name}`;
    else if (tumorid && tumorid != "undefined")
      url = `/launchtracker/api/v1/data/filter?tumorId=${tumorid}`;
    else if (rdfidname && rdfidname != "undefined")
      url = `/launchtracker/api/v1/data/filter?study=${rdfidname}`;
    else
      url = `/launchtracker/api/v1/data/filter?year=${year}`;

    if (countryFilterArr.length > 0) {
      url += `&countryId=${countryFilterArr.join(',')}`;
    }
    if (indicationFilterArr.length > 0) {
      let indicationArr = indicationFilterArr.map((item, index) => {
        return item.replaceAll("+", "$");
      })
      //   indicationArr = indicationArr.replace("+", "&")
      url += `&indication=${indicationArr.join(',')}`;
    }
    if (regionFilterArr.length > 0) {
      url += `&regionId=${regionFilterArr.join(',')}`;
    }
    if (studyFilterArr.length > 0) {
      url += `&study=${studyFilterArr.join(',')}`;
    }

    GetAPIRequest({
      url: url,
    }).then((response) => {
      setIsLoading(false);
      const results = sortByCommercialDate(response);
      const uniqueProperties = [
        {
          uniqueCountries: [...new Set(results.map((item) => item.country))],
        },
        {
          uniqueRegion: [...new Set(results.map((item) => item.region))],
        },
        {
          uniqueIndication: [
            ...new Set(results.map((item) => item.indication)),
          ],
        },
        {
          uniqueStudy: [...new Set(results.map((item) => item.study))],
        },
        {
          uniqueYears: [
            ...new Set(results.map((item) => item.regulatory_approval)),
          ],
        },
      ];
      if (!isFiltersApplied)
        setFilters(uniqueProperties);

      setIsFiltersApplied(true);

      setSearchData([]);
      setData(results);

      let realeseList = results.filter(x => x.commercial_launch != "");
      setReleaseData(realeseList);
      if (results.length > 0 && realeseList.length > 0)
        getTimeLineView(results);
      setCSVFileData(results);
    });
  }


  const downloadExcelFile = () => {

    var url = "";
    if (name && name != "undefined")
      url = `/launchtracker/api/v1/data/export?brandId=${name}`;
    else if (tumorid && tumorid != "undefined")
      url = `/launchtracker/api/v1/data/export?tumorId=${tumorid}`;
    else if (rdfidname && rdfidname != "undefined")
      url = `/launchtracker/api/v1/data/export?study=${rdfidname}`;
    else
      url = `/launchtracker/api/v1/data/export?year=${year}`;

    if (countryFilterArr.length > 0) {
      url += `&countryId=${countryFilterArr.join(',')}`;
    }
    if (indicationFilterArr.length > 0) {
      let indicationArr = indicationFilterArr.map((item, index) => {
        return item.replaceAll("+", "$");
      })
      url += `&indication=${indicationArr.join(',')}`;
    }
    if (regionFilterArr.length > 0) {
      url += `&regionId=${regionFilterArr.join(',')}`;
    }
    if (studyFilterArr.length > 0) {
      url += `&study=${studyFilterArr.join(',')}`;
    }

    GetAPIRequest({
      url: url,
    }).then((response) => {

      const temp = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = temp;
      link.setAttribute("download", 'brandlist.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  /*End*/
  const rootSubmenuKeys = ["Region", "Markets", "study", "indication"];
  const [openKeys, setOpenKeys] = React.useState(["Region"]);


  const onOpenChange = (keys) => {

    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };


  const [collapsed, setColapse] = useState(true);
  const toggleCollapsed = () => {

    setColapse(!collapsed);
  };

  /*
  am charts serpentine-timeline view starts
  */

  function getTimeLineView(results) {

    chart = am4core.create(
      "timelineViewChart",
      am4plugins_timeline.SerpentineChart
    );

    chart.curveContainer.padding(100, 20, 50, 20);
    chart.levelCount = 3;
    chart.yAxisRadius = am4core.percent(20);
    chart.yAxisInnerRadius = am4core.percent(2);
    chart.maskBullets = false;

    var colorSet = new am4core.ColorSet();

    chart.dateFormatter.dateFormat = "HH";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd  HH:mm";
    results = results.filter(item => (item.commercial_launch &&
      (!item.commercial_launch.includes("Invalid date") || !item.commercial_launch.includes(""))));
    results.sort(function (a, b) {
      return a.commercial_launch < b.commercial_launch ? -1 : 0;
    })
    chart.data = results.map((item, index) => {
      if (
        item.commercial_launch &&
        (!item.commercial_launch.includes("Invalid date") || !item.commercial_launch.includes(""))
      ) {
        console.log("item", moment(item.commercial_launch).format('yyyy-MM-DD h:mm'));
        let nextEventStartDate = "";
        if (results.length != index + 1)
          nextEventStartDate = results[index + 1].commercial_launch;
        else
          nextEventStartDate = item.commercial_launch;
        console.log("nextEventStartDate", moment(nextEventStartDate).format('yyyy-MM-DD h:mm'));
        return {
          "category": item.brand ? item.brand : "",
          "start": moment(item.commercial_launch).format('yyyy-MM-DD 6:00'),
          "end": moment(nextEventStartDate).format('yyyy-MM-DD 7:00'),
          "icon": getCountryIcon(item.country.trim()),
          // letter: `${item.country} ${item.study} `,
          "description": `${item.country} ${item.study}, ${moment(
            item.commercial_launch
          ).format("MMM DD")
            } `,
          "color": getBrandColor(item.brand, item.commercial_launch),
          //textColor: getBrandBackgroundColor(item.brand),
        };
      }
    });
    //chart.data = chart.data.filter(x => x != undefined);
    console.log("chart data", chart.data)

    chart.fontSize = 10;
    chart.tooltipContainer.fontSize = 10;

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = { count: 30, timeUnit: "minute" };
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.line.strokeOpacity = 0.5;
    dateAxis.tooltip.background.fillOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 5;
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    dateAxis.tooltip.label.paddingTop = 7;
    dateAxis.endLocation = 0;
    dateAxis.startLocation = -0.5;

    var labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.4;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
    labelTemplate.background.fillOpacity = 1;
    labelTemplate.padding(7, 7, 7, 7);

    var series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
    series.columns.template.height = am4core.percent(15);

    series.dataFields.openDateX = "start";
    series.dataFields.dateX = "end";
    series.dataFields.categoryY = "category";
    series.baseAxis = categoryAxis;
    series.columns.template.propertyFields.fill = "color"; // get color from data
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.fillOpacity = 0.6;

    var imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet());
    imageBullet1.locationX = 1;
    imageBullet1.propertyFields.stroke = "color";
    imageBullet1.background.propertyFields.fill = "color";
    imageBullet1.propertyFields.tooltipText = "description";
    imageBullet1.image = new am4core.Image();
    imageBullet1.image.propertyFields.href = "icon";
    imageBullet1.image.scale = 0.5;
    imageBullet1.circle.radius = am4core.percent(100);
    imageBullet1.dy = -5;


    var textBullet = series.bullets.push(new am4charts.LabelBullet());
    textBullet.label.propertyFields.text = "text";
    textBullet.disabled = true;
    textBullet.propertyFields.disabled = "textDisabled";
    textBullet.label.strokeOpacity = 0;
    textBullet.locationX = 1;
    textBullet.dy = - 100;
    textBullet.label.textAlign = "middle";

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.align = "center"
    chart.scrollbarX.width = am4core.percent(75);
    chart.scrollbarX.opacity = 0.5;

    var cursor = new am4plugins_timeline.CurveCursor();
    chart.cursor = cursor;
    cursor.xAxis = dateAxis;
    cursor.yAxis = categoryAxis;
    cursor.lineY.disabled = true;
    cursor.lineX.strokeDasharray = "1,4";
    cursor.lineX.strokeOpacity = 1;

    dateAxis.renderer.tooltipLocation2 = 0;
    categoryAxis.cursorTooltipEnabled = false;


    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [
      {
        "label": "...",
        "menu": [
          { "type": "png", "label": "PNG" },
          { "type": "jpg", "label": "JPG" },
        ]
      }
    ];
    // var label = chart.createChild(am4core.Label);
    // label.text = "Another unlucky day in the office."
    // label.isMeasured = false;
    // label.y = am4core.percent(40);
    // label.x = am4core.percent(50);
    // label.horizontalCenter = "middle";
    // label.fontSize = 20;

  }

  const options =
    countries.length > 0 &&
    countries.map((item, index) => {
      return {
        label: item.country_name,
        value: item.country_id,
      };
    });

  const studyOptions =
    studyFilter.length > 0 &&
    studyFilter.map((item, index) => {
      return {
        label: item.text,
        value: item.text,
      };
    });
  const indicationOptions =
    indicationFilter.length > 0 &&
    indicationFilter.map((item, index) => {
      return {
        label: item.text,
        value: item.text,
      };
    });
  const regionOptions =
    regions.length > 0 &&
    regions.map((item, index) => {
      return {
        label: item.region_name,
        value: item.region_id,
      };
    });
  /* Collapse start here */
  const { Panel } = Collapse;

  function callback(key) {
    console.log(key);
  }

  /* Collapse end here */

  function downloadAsImage() {
    //   let exporting = am5plugins_exporting.Exporting.new(CHART_ID, {
    //     menu: am5plugins_exporting.ExportingMenu.new(CHART_ID, {})
    //   });
    //   exporting.download()
  }

  const updateBrands = (brandsNewArray) => {

    if (brandsNewArray.length > 0) {
      var url = "/launchtracker/api/v1/data/v1/save";
      PostAPIRequest({
        url: url,
        data: brandsNewArray
      }).then(response => {
        message.success("Successfully updated")
      });
    }
  }

  const tableRef = useRef();

  return (
    <div className="HomeWrapper">
      <Layout>
        <div
          className="HomeWrapper"
          style={{ position: "relative", height: height - 116 + "px", overflow: "hidden" }}
        >
          <Layout style={{ minHeight: "1000px" }}>
            <div className="sidebar" style={{ height: height - 118 + "px", overflow: "auto", minHeight: "260px", backgroundColor: "#fff" }}>
              <Sider width={200} className="site-layout-background">
                <Menu
                  defaultOpenKeys={["Brands"]}
                  mode="inline"
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}

                >
                  <SubMenu key="Region" title="Region" >
                    <Checkbox.Group onChange={filterByRegion} value={regionFilterArr} style={{ overflow: "auto", maxHeight: height - 370 + "px" }}>
                      {regionOptions &&
                        regionOptions.map((item, index) => {
                          var regionKey = "RegionKey" + index;
                          return (
                            <Menu.Item
                              key={regionKey}
                              style={{ maxHeight: "200px" }}
                            >
                              <Checkbox value={item.value} key={index}>
                                {item.label}
                              </Checkbox>
                            </Menu.Item>
                          );
                        })}
                    </Checkbox.Group>
                  </SubMenu>

                  <SubMenu key="Markets" title="Markets"  >
                    <Checkbox.Group onChange={filterByCountry} value={countryFilterArr} style={{ overflow: "auto", maxHeight: height - 370 + "px" }}>
                      {options &&
                        options.map((item, index) => {
                          var submenukey = "MarketsKey" + index;
                          return (
                            <Menu.Item
                              key={submenukey}
                              style={{ maxHeight: "200px" }}
                            >
                              <Checkbox value={item.value} key={index}>
                                {item.label}
                              </Checkbox>
                            </Menu.Item>
                          );
                        })}
                    </Checkbox.Group>

                  </SubMenu>

                  <SubMenu key="study" title="Study"  >
                    <Checkbox.Group onChange={filterByStudy} value={studyFilterArr} style={{ overflow: "auto", maxHeight: height - 300 + "px", overflow: "auto" }}>
                      {studyOptions &&
                        studyOptions.map((item, index) => {
                          var marketsKey = "MarketsKey" + index;
                          return (
                            <Menu.Item
                              key={marketsKey}
                              style={{ maxHeight: "200px" }}
                            >
                              <Checkbox value={item.value} key={index}>
                                {item.label}
                              </Checkbox>
                            </Menu.Item>
                          );
                        })}
                    </Checkbox.Group>

                  </SubMenu>
                  <SubMenu key="indication" title="Indication" >
                    <Checkbox.Group onChange={filterByIndication} value={indicationFilterArr} style={{ overflow: "auto", maxHeight: height - 300 + "px" }}>
                      {indicationOptions &&
                        indicationOptions.map((item, index) => {
                          var indicationKey = "IndicationKey" + index;
                          return (
                            <Menu.Item
                              key={indicationKey}
                              style={{ maxHeight: "200px" }}
                            >
                              <Checkbox value={item.value} key={index}>
                                {item.label}
                              </Checkbox>
                            </Menu.Item>
                          );
                        })}
                    </Checkbox.Group>
                  </SubMenu>
                </Menu>
              </Sider>
            </div>
            <Layout
              style={{
                padding: "24px 24px 24px",
                height: height - 116 + "px",
                overflow: "auto",
                position: "relative"
              }}
            > <Row style={{

            }}>
                <Col span={24}>
                  <button onClick={history.goBack} style={{ marginBottom: "10px" }} className="backbtn"><CaretLeftOutlined /> Back</button>
                  {name && name != "undefined" ? (
                    // data && data.length > 0 ? (
                    //   data[0].brand &&
                        brandLogos[brandname.toLowerCase()] ? (

                        <div
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "20px",
                            backgroundColor: "#fff",
                            color: "#830051",
                            borderRadius: 10,
                            border: "1px solid #ff9900",
                            display: "inline-block",
                            padding: "6px 11px",
                            marginBottom: "20px",

                          }}
                        >
                          <Image
                            style={{
                              padding: "10px 10px", height: "auto", maxWidth: 180, objectFit: "contain"
                            }}
                            preview={false}
                            src={brandLogos[brandname.toLowerCase()]}
                          /></div>
                      ) : (


                        <div
                          style={{

                            marginBottom: "20px",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "20px",
                            backgroundColor: "#fff",
                            color: "#830051",
                            borderRadius: 10,
                            border: "1px solid #ff9900",
                            display: "inline-block",
                            padding: 15,

                          }}
                        ><span style={{ padding: "10px 10px" }}>{brandname}</span></div>
                      )
                    // ) : null
                  ) : (



                    <div
                      style={{
                        marginBottom: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                        backgroundColor: "#fff",
                        color: "#830051",
                        borderRadius: 10,
                        border: "1px solid #ff9900",
                        display: "inline-block",
                        padding: 15,

                      }}
                    >
                      <span>{year && year != "undefined" ? year : (tumorname && tumorname != undefined ? tumorname : rdfidname)}</span>
                    </div>
                  )}</Col></Row>

              <Collapse className="chart-ctn" defaultActiveKey={['1']} onChange={callback}>
                <Panel header="ONCOLOGY SUMMARY" key="1">
                  <Spin size="large" spinning={isLoading}>
                    <Row>
                      <Col span={24}>
                        {
                          data.length > 0 && releaseData.length > 0 ?

                            <div
                              id={CHART_ID}
                              style={{
                                width: "100%",
                                height: "500px",
                                margin: "10px 0",
                                backgroundColor: "#fff",
                              }}
                            ></div>
                            :
                            <div className="no-data">  No data found </div>
                        }

                      </Col>
                    </Row>
                  </Spin>
                </Panel>
              </Collapse>
              <div>

                {data.length > 0 &&
                  <Space className=" search-ctn" style={{ marginBottom: "7px", display: "block" }}>
                    <div className="flex-middle-container ">
                      <Layout>
                        <Row>
                          <Col span={24}>
                            <div className="flex-end-container">
                              <div
                                style={{
                                  flex: 2,
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Search
                                  className="admin-search"
                                  style={{ width: 400 }}
                                  placeholder="Search Key"
                                  enterButton
                                  loading={isSearch}
                                  onKeyUp={(d) => onSearch(d)}
                                  size={"large"}
                                  value={searchValue}
                                  onChange={(e) => setSearchValue(e.target.value)}

                                />

                              </div>
                              <Button
                                className="clear-btn"
                                style={{ marginLeft: "15px" }}
                                size="large"
                                onClick={clearAll}
                              >
                                Clear filters
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Layout>
                    </div>

                  </Space>
                }
              </div>
              <div>

              </div>
              <div className="responsive-table" style={{ paddingTop: "60px" }}>
                {

                  data.length > 0 &&
                  <div className="table-btns" style={{
                    position: "absolute", top: "0px",
                    position: "absolute",
                    right: "0px",
                  }}>
                    <Button style={{ marginRight: "10px" }} className="ant-btn ant-btn-primary table-save"
                      onClick={() => downloadExcelFile()}>
                      <FileExcelOutlined /> Export

                    </Button>
                    {isUser &&
                      <Button
                        type="primary"
                        className="table-save"
                        onClick={() => tableRef.current.updateBrands()}
                      >
                        Save
                      </Button>
                    }
                  </div>
                }
                {
                  searchData.length > 0 ? <TableView brands={brands} data={searchData} ref={tableRef}></TableView>
                    : data.length > 0 && <TableView brands={brands} data={data} ref={tableRef}></TableView>
                }


              </div>

            </Layout>
          </Layout>
        </div>
      </Layout >
    </div >
  );
};

export default Home;
