import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import './TableView.css';
import {
    AudioOutlined,
    DeleteOutlined,
    EditOutlined,
    CloseOutlined,
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
    CaretLeftOutlined,
} from "@ant-design/icons";
import {

    DatePicker,
    Form,
    Image,
    Input,
    InputNumber,
    Popconfirm,
    Space,
    Table,
    Tabs,
    Typography,
    Checkbox,
    Card,
    Col,
    Row,
    Radio,
    Collapse, Button, Alert, message
} from "antd";

import moment from "moment";
import { color } from '@amcharts/amcharts4/core';
import { PostAPIRequest, GetAPIRequest } from '../../../services';
import { useImperativeHandle } from 'react';

const TableView = forwardRef((props, ref) => {
    const [dataSource, setDataSource] = useState([]);
    const [brands, setBrands] = useState([]);

    useEffect(() => {

        setDataSource(props.data);
        console.log("table view data", props.data)
        if (props.brands.length > 0)
            setBrands(props.brands);
        else
            getBrandsList();

    }, [props.data]);



    function getBrandsList() {
        var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
        GetAPIRequest({
            url: url,
        }).then((response) => {
            if (response) {
                setBrands(response);
            }
        });
    }


    const EditableContext = React.createContext(null);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = (props) => {
        const {
            record,
            dataIndex,
            title,
            editable,
            inputType,

            children,
            currentRowValues,
            handleInputChange,
            ...restProps
        } = props;

        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            var parsedDate = "";
            setEditing(!editing);
            if (record[dataIndex] == "") {
                form.setFieldsValue({
                    [dataIndex]: ""
                });
            }
            else {
                parsedDate = moment(record[dataIndex], "MMM-YYYY", true).format();

                form.setFieldsValue({
                    [dataIndex]: moment(parsedDate)
                });
            }
        };

        const save = async (date, dateString) => {

            try {
                const values = await form.validateFields();
                let keys = Object.keys(values)[0];

                toggleEdit();
                var updatedDate = moment(dateString).format("MMM-YYYY");
                record[keys] = updatedDate;
                handleSave({
                    ...record
                });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;
        var inputNode = inputType === "number" ? <InputNumber ref={inputRef} /> : <Input ref={inputRef} />;
        if (inputType === "date") {
            // inputNode = <DatePicker format={"YYYY-MM-DD"} ref={inputRef}
            inputNode = <DatePicker format={"MMM-YYYY"} ref={inputRef}
                onPressEnter={save} onBlur={toggleEdit} onChange={save} picker="month" value="" />;
        }
        var isAdmin = false;

        if (record != undefined) {
            var brandObj = brands.filter((x) => x.brand == record.brand)[0];
            if (brandObj) {
                isAdmin = brandObj.is_admin == 1 ? true : false;
            }
        }
        if (isAdmin) {
            console.log("check the edit admin")
            if (editable) {
                console.log("check the edit child")
                childNode = editing ? (
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        {inputNode}
                        {/* <Input ref={inputRef} onPressEnter={save} onBlur={save} /> */}
                    </Form.Item>
                ) : (
                    children[1] == undefined || children[1] == "" ?
                        <div
                            className="editable-cell-value-wrap dateformatdiv"

                            onClick={() => toggleEdit()}
                        >
                            {"MMM-YYYY"}
                        </div>
                        : <div
                            className="editable-cell-value-wrap"
                            style={{
                                paddingRight: 0,
                            }}
                            onClick={() => toggleEdit()}
                        >
                            {children}
                        </div>
                );
            }

            return <td {...restProps}>{childNode}</td>;
        }
        else
            return <td {...restProps}>{childNode}</td>;
    };


    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };

    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;
    const [isSearch, setIsSearch] = useState(false);
    const [brandId, setBrandId] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };
    const defaultColumns = [

        {
            title: "Region",
            dataIndex: "region",
            inputType: "text",
            key: "region",
            // editable: true,
            //  filters: regionFilter,
            filteredValue: filteredInfo.region || null,
            onFilter: (value, record) => record.region.includes(value),
            sorter: (a, b) => a.region.localeCompare(b.region),
            sortOrder: sortedInfo.columnKey === "region" && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: "Country",
            dataIndex: "country",
            inputType: "text",
            key: "country",
            //filters: countryFilter,
            filteredValue: filteredInfo.country || null,
            onFilter: (value, record) => record.country.includes(value),
            sorter: (a, b) => a.country.localeCompare(b.country),
            sortOrder: sortedInfo.columnKey === "country" && sortedInfo.order,
            //editable: true,
        },
        {
            title: "Study",
            dataIndex: "study",
            inputType: "text",
            key: "study",
            // filters: studyFilter,
            filteredValue: filteredInfo.study || null,
            onFilter: (value, record) => record.study.includes(value),
            sorter: (a, b) => a.study.localeCompare(b.study),
            sortOrder: sortedInfo.columnKey === "study" && sortedInfo.order,
            // editable: true,
        },
        {
            title: "Indication",
            dataIndex: "indication",
            inputType: "text",
            key: "indication",
            // filters: indicationFilter,
            filteredValue: filteredInfo.indication || null,
            onFilter: (value, record) => record.indication.includes(value),
            sorter: (a, b) => a.indication.localeCompare(b.indication),
            sortOrder: sortedInfo.columnKey === "indication" && sortedInfo.order,
            //editable: true,
        },
        {
            title: "Tumor Type",
            dataIndex: "tumor_name",
            inputType: "text",
            key: "tumor_name",
            // filters: indicationFilter,
            filteredValue: filteredInfo.indication || null,
            onFilter: (value, record) => record.tumor_name.includes(value),
            sorter: (a, b) => a.tumor_name.localeCompare(b.tumor_name),
            sortOrder: sortedInfo.columnKey === "tumor_name" && sortedInfo.order,
            //editable: true,
        },
        {
            title: "Regulatory Approval",
            dataIndex: "regulatory_approval",
            inputType: "date",
            key: "regulatory_approval",
            // filters: yearFilter,
            filteredValue: filteredInfo.regulatory_approval || null,
            onFilter: (value, record) => record.regulatory_approval.includes(value),
            sorter: function (a, b) {
                const distantFuture = new Date(8640000000000000);
                let date1 = a.regulatory_approval != "" ? moment(a.regulatory_approval, "DD-MMM-YYYY") : distantFuture
                let date2 = b.regulatory_approval != "" ? moment(b.regulatory_approval, "DD-MMM-YYYY") : distantFuture;
                return date1 < date2 ? -1 :
                    (date1 > date2 ? 1 : 0)
            },
            // sorter: (a, b) => a.regulatory_approval < b.regulatory_approval ? -1 :
            //     (a.regulatory_approval > b.regulatory_approval ? 1 : 0),
            sortOrder: sortedInfo.columnKey === "regulatory_approval" && sortedInfo.order,
            editable: true,
        },
        {
            title: "Commercial Launch",
            dataIndex: "commercial_launch",
            inputType: "date",
            key: "commercial_launch",
            filteredValue: filteredInfo.commercial_launch || null,
            onFilter: (value, record) => record.commercial_launch.includes(value),
            sorter: function (a, b) {
                const distantFuture = new Date(8640000000000000);
                let date1 = a.commercial_launch != "" ? moment(a.commercial_launch, "DD-MMM-YYYY") : distantFuture
                let date2 = b.commercial_launch != "" ? moment(b.commercial_launch, "DD-MMM-YYYY") : distantFuture;
                return date1 < date2 ? -1 :
                    (date1 > date2 ? 1 : 0)
            },
            // sorter: (a, b) => a.commercial_launch < b.commercial_launch ? -1 :
            //     (a.commercial_launch > b.commercial_launch ? 1 : 0),
            sortOrder: sortedInfo.columnKey === "commercial_launch" && sortedInfo.order,
            editable: true,
        },
        {
            title: "Reimbursement Approval",
            width: 150,
            dataIndex: "reimbursement_approval",
            inputType: "date",
            key: "reimbursement_approval",
            filteredValue: filteredInfo.commercial_launch || null,
            onFilter: (value, record) => record.reimbursement_approval.includes(value),

            sorter: function (a, b) {
                const distantFuture = new Date(8640000000000000)
                let date1 = a.reimbursement_approval != "" ? moment(a.reimbursement_approval, "DD-MMM-YYYY") : distantFuture
                let date2 = b.reimbursement_approval != "" ? moment(b.reimbursement_approval, "DD-MMM-YYYY") : distantFuture;
                return date1 < date2 ? -1 :
                    (date1 > date2 ? 1 : 0)
            },
            // sorter: (a, b) => moment(a.reimbursement_approval, "MMM-YYYY") < moment(b.reimbursement_approval, "MMM-YYYY") ? -1 :
            //     (moment(a.reimbursement_approval, "MMM-YYYY") > moment(b.reimbursement_approval, "MMM-YYYY") ? 1 : 0),
            sortOrder: sortedInfo.columnKey === "reimbursement_approval" && sortedInfo.order,
            editable: true,
        },
        {
            title: "",
            width: 50,
            dataIndex: "operation",
            render: (_, record) => {
                var brandObj = props.brands.filter((x) => x.brand == record.brand)[0];
                var isAdmin = false;
                if (brandObj) {
                    isAdmin = brandObj.is_admin == 1 ? true : false;
                }
                return (
                    <Popconfirm disabled={!isAdmin}
                        title="Sure to delete?"
                        onConfirm={() => {
                            handleDelete(record.key);
                        }}
                    >
                        <div className="delete-ctn">
                            <DeleteOutlined />
                        </div>
                    </Popconfirm>
                )
            }
        },
    ];

    const edit = (record) => {
        setBrandId(record.brand_id);
        var launchdate = moment(
            record.commercial_launch,
            "YYYY-MM-DD",
            true
        ).format();
        var regulatoryapprovalDate = moment(
            record.regulatory_approval,
            "YYYY-MM-DD",
            true
        ).format();
        var reimbursementapprovalDate = moment(
            record.reimbursement_approval,
            "YYYY-MM-DD",
            true
        ).format();
        if (launchdate != "Invalid date")
            record.commercial_launch = moment(launchdate);

        if (regulatoryapprovalDate != "Invalid date")
            record.regulatory_approval = moment(regulatoryapprovalDate);

        if (reimbursementapprovalDate != "Invalid date")
            record.reimbursement_approval = moment(reimbursementapprovalDate);

        // form.setFieldsValue({
        //   ...record,
        // });
        setEditingKey(record.key);
    };

    const cancel = (key) => {

        const editedRow = props.data.filter((x) => x.key == key)[0];
        if (editedRow) {
            editedRow.regulatory_approval = moment(editedRow.regulatory_approval).format("YYYY-MM-DD")
                == "Invalid date" ? "" : moment(editedRow.regulatory_approval).format("YYYY-MM-DD");;
            editedRow.commercial_launch = moment(editedRow.commercial_launch).format(
                "YYYY-MM-DD") == "Invalid date" ? "" : moment(editedRow.commercial_launch).format("YYYY-MM-DD");
            editedRow.reimbursement_approval = moment(
                editedRow.reimbursement_approval
            ).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(
                editedRow.reimbursement_approval
            ).format("YYYY-MM-DD");
        }

        setEditingKey("");
    };
    const save = async (key) => {
        try {
            const row = ""//await form.validateFields();
            row.data_id = key;
            //updateBrand(row);
            const newData = [...props.data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                //  setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                //    setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };


    const [brandsNewArray, setBrandNewArray] = useState([]);


    const handleSave = (row) => {

        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        updatedNewBrandArray(row)
    };

    const updatedNewBrandArray = (row) => {

        var obj = {
            data_id: row.key,
            brand_id: row.brand_id,
            regulatory_approval: row.regulatory_approval != "" ? moment(row.regulatory_approval) : "",
            reimbursement_approval: row.reimbursement_approval != "" ? moment(row.reimbursement_approval) : "",
            commercial_launch: row.commercial_launch != "" ? moment(row.commercial_launch) : "",
        }
        let isExist = brandsNewArray.filter(x => x.data_id == row.key);
        if (isExist.length > 0) {
            brandsNewArray.map((item, index) => {

                if (item.data_id == row.key) {
                    item.regulatory_approval = obj.regulatory_approval;
                    item.reimbursement_approval = obj.reimbursement_approval;
                    item.commercial_launch = obj.commercial_launch;
                }
            })
        }
        else
            brandsNewArray.push(obj);

        setBrandNewArray(brandsNewArray)
        console.log("brnadNewArray", brandsNewArray);
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                record,

                handleSave,
            }),
        };
    });

    useImperativeHandle(ref, () => ({
        updateBrands() {

            if (brandsNewArray.length > 0) {
                var url = "/launchtracker/api/v1/data/v1/save";
                PostAPIRequest({
                    url: url,
                    data: brandsNewArray
                }).then(response => {
                    message.success("Successfully updated")
                });
            }
        },
        clearAll() {
            setSortedInfo({});
        }
    }))




    return (
        <>
            <div className='flex-container' style={{ justifyContent: "flex-end", justifyItems: "flex-end", textAlign: "right" }}>

                {/* <Button
                    type="primary"
                    className="table-save"
                    onClick={() => updateBrands()}
                >
                    Save
                </Button> */}
                {/* {
                    isUser ? <Button
                        type="primary"
                        className="table-save"
                        onClick={() => updateBrands()}
                    >
                        Save
                    </Button> :
                        <div className='table-save'>

                        </div>
                } */}
            </div>
            <Table
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                onChange={handleChange}
            />

        </>
    );
})

export default TableView;