import React, { useEffect, useState, useRef } from "react";
import {
    Button, DatePicker, Form, Image, Input,
    InputNumber,
    Popconfirm, Space, Table, Tabs, Typography,
    Checkbox,
    Select, Tag, Modal, message
} from "antd";
import AddUsers from "../Users/AddUsers";
import {
    DeleteOutlined, EditOutlined, PlusCircleOutlined, DownloadOutlined, FileExcelOutlined
} from "@ant-design/icons";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../services";
import { CSVLink, CSVDownload } from "react-csv";


const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

const Users = (props) => {

    const [data, setData] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    //const [isSearch, setIsSearch] = useState(false);
    const [usersForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;
    const [brands, setBrands] = useState([]);
    const childRef = useRef(null);
    const [record, setRecord] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [csvData, setCSVData] = useState([]);

    const [title, setTitle] = useState("Add User")

    function getUsers() {
        var url = '/launchtracker/api/v1/user/brand_user_list'
        GetAPIRequest({
            url: url
        }).then((response) => {
            const results = response.map(row => (
                {
                    key: row.user_id,
                    role_id: row.role_id,
                    prid_id: row.prid_id,
                    email: row.email,
                    brands: row.brands.map((item, index) => {
                        return {
                            "brand_id": item.brand_id,
                            "brand": item.brand
                        }
                    })
                }
            ));
            setData(results);
            setCSVFileData(results)
        })
    }

    const setCSVFileData = (response) => {
        const results = response.map(row => (
            {
                "Prid Id": row.prid_id,
                "Email": row.email,
                "Brands": row.brands.map((item, index) => item.brand + ",")
            }
        ));
        setCSVData(results);
    }

    useEffect(() => {
        getUsers();
        getBrandsList();

    }, [])


    function getBrandsList() {
        var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
        GetAPIRequest({
            url: url
        })
            .then(response => {
                if (response) {
                    setBrands(response);
                }
            })
    }
    /*Table code*/

    const cancel = (key) => {

        setEditingKey("");
    };

    const edit = (record) => {
        setTitle("Edit User")
        if (record.role_id == 2) {
            setIsRequired(true);
        }
        else {
            setIsRequired(false);
        }
        usersForm.setFieldsValue({
            ...record,
        });
        setRecord(record);
        showModal();
        setFormField(record);
        setEdit(true);
        //setEditingKey(record.key);
    };



    function handleDelete(key) {
        var url = '/launchtracker/api/v1/user/' + key
        DeleteAPIRequest({
            url: url
        }).then((response) => {
            getUsers()
            message.success("Successfully deleted")
        }).catch((error) => {
            message.error("Something went wrong. Please try again later.")
        });
    }



    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        var inputNode = inputType === "number" ? <InputNumber /> : <Input />;


        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: inputType === "date" ? false : true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    const save = async (key) => {
        try {
            const row = await usersForm.validateFields();
            row.id = key;
            const newData = [...data];

            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };
    const columns = [
        {

            title: "PRID",
            dataIndex: "prid_id",
            inputType: "text",
            key: "prid_id",
            editable: true,
            filters: [],
            filteredValue: filteredInfo.data || null,
            onFilter: (value, record) => record.prid_id.includes(value),
            //sorter: (a, b) => a.prid_id.length - b.prid_id.length,
            sorter: (a, b) => a.prid_id.localeCompare(b.prid_id),
            sortOrder: sortedInfo.columnKey === "prid_id" && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: "Brands",
            dataIndex: "brands",
            inputType: "select",
            key: "brands",
            filters: [],
            filteredValue: filteredInfo.brands || null,
            onFilter: (value, record) => record.brands.includes(value),
            // sorter: (a, b) => a.brands.localeCompare(b.brands),
            sorter: (a, b) => a.brands.length - b.brands.length,
            sortOrder: sortedInfo.columnKey === "brands" && sortedInfo.order,
            editable: true,
            render: (_, record) => {
                return (
                    record.brands.map((item, index) => {
                        return (<Tag  >{item.brand}</Tag>)
                    })
                )
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            inputType: "text",
            key: "email",
            filters: [],
            filteredValue: filteredInfo.email || null,
            onFilter: (value, record) => record.email.includes(value),
            // sorter: (a, b) => a.email.length - b.email.length,
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
            editable: true,
        },
        {
            title: "",
            width: 80,
            dataIndex: "action",
            render: (_, record) => {

                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
                            <a className="cancel-btn atn-btn">Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        {
                            <div className="edit-ctn">
                                <Space>
                                    <Typography.Link
                                        disabled={editingKey !== ""}
                                        onClick={() => edit(record)}
                                    >
                                        <EditOutlined /> Edit
                                    </Typography.Link>
                                </Space>
                            </div>
                        }
                    </>
                );
            },
        },
        {
            title: '',
            width: 50,
            dataIndex: 'operation',
            render: (_, record) => {

                return (
                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                        handleDelete(record.key)
                    }}>  <div className="delete-ctn">
                            <DeleteOutlined /></div>
                    </Popconfirm>
                )
            }
        },

    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const clearAll = () => {
        setSortedInfo({});
        setFilteredInfo({});
    };

    function getUsersByName(name) {

    }
    function callback(key) {
        getUsers();
        console.log(key);
    }

    // Modal code starts
    const [visible, setVisible] = React.useState(props.isOpen);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [Users, setUsers] = useState([]);

    const showModal = () => {
        setVisible(true);
    };



    const handleOk = async () => {
 
        var row = await form.validateFields();
        row.brands = row.brands == undefined ? [] : row.brands;
        console.log("Received values of form: ", row);
        if (isEdit) {
            row = {
                "user_id": row.key,
                "brands": row.brands,
                "prid_id": row.prid_id,
                "role_id": row.role_id,
                "email": row.email
            }
        }

        var url = "/launchtracker/api/v1/user/save";
        PostAPIRequest({
            url: url,
            data: row,
        }).then((response) => {
            setConfirmLoading(true);
            setTimeout(() => {
                setVisible(false);
                setConfirmLoading(false);
            }, 1000);
            callback();
            form.resetFields();
            message.success("Successfully updated")
        }).catch(err => {
            message.error("Something went wrong. Please try again later.")
        })
        setEdit(false);

    };

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setEdit(false);
        setVisible(false);
        form.resetFields();
    };



    const [selectedBrands, setSelectedBrands] = useState([""])
    function setFormField(record) {
        const arr = [];
        for (let i = 0; i < record.brands.length; i++)
            arr.push(record.brands[i].brand_id);

        setSelectedBrands(arr);
        console.log("selectedBrands", arr)
        form.setFieldsValue({
            key: record.key,
            role_id: record.role_id,
            brands: arr,
            prid_id: record.prid_id,
            email: record.email
        });
    }


    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    function getBrandsList() {
        var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
        GetAPIRequest({
            url: url
        })
            .then(response => {
                if (response) {
                    const _response = response.sort(GetSortOrder("brand"));
                    console.log("_response", _response);
                    setBrands(_response);
                }
            })
    }

    const [autoCompleteResult, setAutoCompleteResult] = useState([]);

    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));

    const children = [];
    brands && brands.map((item, index) => {
        children.push(<Option key={item.brand} value={item.brand_id}>{item.brand}</Option>);
    });

    const [isRequired, setIsRequired] = useState(false);
    function showBrands(d) {
        if (d == 2)
            setIsRequired(true)
        else
            setIsRequired(false)
    }
    //ends


    return (
        <>
            <div className="users-container admin-table-view">
                <div className="flex-container" style={{ position: "relative" }}>

                    <Space style={{ marginBottom: 16 }}>

                        {/* <AddUsers callback={callback} ref={childRef} record={record} /> */}
                        <Button size="large" type="primary" onClick={() => {
                            showModal()
                            setTitle("Add User")
                        }}>
                            <PlusCircleOutlined />
                            Add User
                        </Button>
                        {
                            csvData.length > 0 && <Button className="ant-btn ant-btn-lg downloadcsv">
                                <CSVLink data={csvData} target="_blank" filename="Users" >
                                    <FileExcelOutlined />      Export                            </CSVLink >
                            </Button>
                        }
                    </Space>
                    <Modal
                        bodyStyle={{ overflowX: "auto", maxHeight: "calc(80vh - 200px)" }}
                        title={title}
                        visible={visible}
                        width={600}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" className="cancel-btn atn-btn" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={confirmLoading}
                                onClick={handleOk}
                            >
                                Submit
                            </Button>,
                        ]}
                    >
                        {/* <UsersNewItem handleCancel={handleCancel} form={form} Users={props.Users} record={props.record} />
       */}
                        <Form
                            {...formItemLayout}
                            form={form}
                            name="user"

                            initialValues={{
                                residence: ['zhejiang', 'hangzhou', 'xihu'],
                                prefix: '86',
                            }}
                            scrollToFirstError
                        >
                            <Form.Item
                                name="key"
                                style={{ display: 'none' }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="role_id"
                                label="User"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input User!",

                                    },
                                ]}
                            >
                                <Select onChange={(d) => showBrands(d)}>
                                    <option value={1}>Super Admin</option>
                                    <option value={2}>Brand Admin</option>
                                    <option value={3}>User</option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="prid_id"
                                label="PRID"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input PRID!",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            {isRequired && <Form.Item
                                name="brands"
                                label="Brands"
                                rules={[
                                    {
                                        required: isRequired,
                                        message: "Please input brands!",
                                    },
                                ]}

                            >
                                <Select mode="multiple" value={selectedBrands}>
                                    {children}
                                </Select>
                            </Form.Item>
                            }
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input email!",
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>

                    </Modal>
                    <Button size="large" onClick={clearAll} className="clear-frt">Clear filters</Button>
                </div>
                <Form form={usersForm} component={false}>
                    <Table scroll={{ x: true }}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        onChange={handleChange}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    >
                    </Table>

                </Form>

            </div>


        </>
    )
}

export default Users;