import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker
} from 'antd';
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../../services";
import moment from "moment";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};


const RegionNewItem = (props) => {

  const brandList =
    () => {

    }
  const [regions, setRegions] = useState([]);

  function getRegions() {
    var url = "/launchtracker/api/v1/master/region";
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setRegions(response);
      })
  }
  useEffect(() => {
    getRegions();
  }, []);

  function getCountryById(d) {
    var url = `/launchtracker/api/v1/master/region/${d}/country`;
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setCountries(response);
        props.form.setFieldsValue({
          country: ''
        })
      })
  }

  const [countries, setCountries] = useState([]);

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));
  return (
    <Form
      {...formItemLayout}
      form={props.form}
      name="brand"

      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    > <Form.Item
      name="region_id"
      label="Region"
      rules={[
        {
          required: true,
          message: "Please input region!"
        },
      ]}
    >
        <Select >
          {
            regions.length > 0 &&
            regions.map((item, index) => {
              return (
                <option key={index} value={item.region_id}> {item.region_name}</option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="country_name"
        label="Country"
        rules={[
          {
            required: true,
            message: "Please input country!"
          },
        ]}
      >
        <Input />
        {/* <Select >
          {
            countries.length > 0 &&
            countries.map((item, index) => {
              return (
                <option key={index} value={item.country_id}> {item.country_name}</option>
              )
            })}
        </Select> */}
      </Form.Item>
    </Form>
  );
};
export default RegionNewItem;
