import React, { useEffect, useState } from "react";
import {
    Button, DatePicker, Form, Image, Input,
    InputNumber,
    Popconfirm, Space, Table, Tabs, Typography, Row, Col,
    Checkbox, message, Modal
} from "antd";
import moment from "moment";
import AddFinancialCalendar from "../FinancialCalendar/AddFinancialCalendar";
import {
    DeleteOutlined, EditOutlined, DownloadOutlined, FileExcelOutlined
} from "@ant-design/icons";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../services";
import { CSVLink, CSVDownload } from "react-csv";


const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};



const FinancialCalendar = (props) => {

    const [financialcalendar, setfinancialcalendar] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    const [financialcalendarForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;
    const [data, setData] = useState([]);
    const [csvData, setCSVData] = useState([]);
    /*api code*/

    useEffect(() => {
        getFinancialCalendarList();
    }, [])

    const getFinancialCalendarList = (props) => {
        var url = `/launchtracker/api/v1/financialCalendar/list`;
        GetAPIRequest({
            url: url
        }).then(response => {
            const results = response.map((row) => ({
                key: row.financial_calendar_id,
                date: moment(row.date).format("YYYY-MM-DD"),
                description: row.description
            })
            );
            setData(results);
            setCSVFileData(results);
        });
    }

    const setCSVFileData = (response) => {
        const results = response.map((row) => ({
            Date: moment(row.date).format("YYYY-MM-DD"),
            Description: row.description
        })
        );
        setCSVData(results);
    }

    const updateFinancialCalendar = (row) => {

        row.date = moment(row.date).format("YYYY-MM-DD");
        var url = `/launchtracker/api/v1/financialCalendar/save`;
        PostAPIRequest({
            url: url,
            data: row
        }).then(response => {
            message.success("Successfully updated")
        }).catch(err => {
            message.error("Something went wrong. Please try again later.")
        })
    }

    /* ends */

    /*Table code*/

    const cancel = (key) => {

        const editedRow = data.filter((x) => x.key == key)[0];
        if (editedRow) {
            editedRow.date = moment(editedRow.date).format("YYYY-MM-DD")
                == "Invalid date" ? "" : moment(editedRow.date).format("YYYY-MM-DD");
        }
        setEditingKey("");
    };

    const [calendarId, setCalendarId] = useState();
    function setFormField(record) {

        setCalendarId(record.key);
        var calendarDate = moment(
            record.date,
            "YYYY-MM-DD",
            true
        ).format();
        let date =
            calendarDate == "Invalid date"
                ? moment(new Date(), "YYYY-MM-DD",
                    true)
                : moment(calendarDate);

        form.setFieldsValue({
            key: record.key,
            date: date,
            description: record.description
        });
    }

    const [record, setRecord] = useState([]);
    const [isEdit, setEdit] = useState(false);

    const edit = (record) => {

        form.setFieldsValue({
            ...record,
        });

        //setRecord(record);
        showModal();
        setFormField(record);
        setEdit(true);

    };

    function handleDelete(key) {
        var url = `/launchtracker/api/v1/financialCalendar/delete/${key}`;
        DeleteAPIRequest({
            url: url
        }).then(response => {
            getFinancialCalendarList();
            message.success("Successfully deleted")
        }).catch((error) => {
            message.error("Something went wrong. Please try again later.")
        });
    }

    const save = async (key) => {

        try {
            const row = await financialcalendarForm.validateFields();
            row.financial_calendar_id = key;
            updateFinancialCalendar(row);
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {

            title: "Date",
            dataIndex: "date",
            inputType: "date",
            key: "date",
            editable: true,
            filters: [],
            filteredValue: filteredInfo.date || null,
            onFilter: (value, record) => record.date.includes(value),

            sorter: (a, b) => a.date < b.date ? -1 :
                (a.date > b.date ? 1 : 0),
            sortOrder: sortedInfo.columnKey === "date" && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: "Description",
            dataIndex: "description",
            inputType: "text",
            key: "description",
            filters: [],
            filteredValue: filteredInfo.description || null,
            onFilter: (value, record) => record.description.includes(value),
            sorter: (a, b) => a.description.localeCompare(b.description),
            sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
            editable: true,
        },
        {
            title: "",
            width: 80,
            dataIndex: "action",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div className="edit-items">
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 12,
                                padding: "5px 12px",
                                backgroundColor: " #830051",
                                color: "#fff"
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
                            <a className="cancel-btn ant-btn">Cancel</a>
                        </Popconfirm>
                    </div>
                ) : (
                    <>
                        {
                            <div className="edit-ctn">
                                <Space>
                                    <Typography.Link
                                        disabled={editingKey !== ""}
                                        onClick={() => edit(record)}
                                    >
                                        <EditOutlined /> Edit
                                    </Typography.Link>
                                </Space>
                            </div>
                        }
                    </>
                );
            },
        },
        {
            title: '',
            width: 50,
            dataIndex: 'operation',
            render: (_, record) => {

                return (
                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                        handleDelete(record.key)
                    }}>
                        <div className="delete-ctn">
                            <DeleteOutlined /></div>
                    </Popconfirm>
                )
            }
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const clearAll = () => {
        setSortedInfo({});
        setFilteredInfo({});
    };

    function callback(key) {
        getFinancialCalendarList();
        console.log(key);
    }

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };


    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        var inputNode = inputType === "number" ? <InputNumber /> : <TextArea rows={4} />;
        if (inputType === "date") {
            inputNode = <DatePicker format={"YYYY-MM-DD"} />;
        }
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: inputType === "date" ? false : true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [form] = Form.useForm();
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = async () => {

        let row = await form.validateFields();
        row.financial_calendar_id = calendarId;
        console.log("Received values of form: ", row);
        row.date = moment(row.date).format("YYYY-MM-DD");
        var url = "/launchtracker/api/v1/financialcalendar/save";
        PostAPIRequest({
            url: url,
            data: row,
        }).then((response) => {
            message.success("Successfully created")
            form.resetFields()
            setConfirmLoading(true);
            setTimeout(() => {
                setVisible(false);
                setConfirmLoading(false);
            }, 1000);
            callback();
        }).catch((error) => {
            message.error("Something went wrong. Please try again later.")
        });
    };

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setVisible(false);
        form.resetFields();
    };
    return (
        <>
            <div className="financialcalendar-container admin-table-view">
                <Row>
                    <Col span={24}>
                        <div class="flex-container">


                            <Space style={{ marginBottom: 16, alignItems: 'flex-end', justifyContent: 'flex-end' }}>


                                <AddFinancialCalendar callback={callback} financialcalendar={financialcalendar} />
                                {
                                    csvData.length > 0 && <Button className="ant-btn ant-btn-lg downloadcsv">
                                        <CSVLink data={csvData} target="_blank" filename="Calendar" >

                                            <FileExcelOutlined /> Export
                                        </CSVLink >
                                    </Button>
                                }
                            </Space>
                            <Modal
                                bodyStyle={{ overflowX: "auto", maxHeight: "calc(80vh - 200px)" }}
                                title="Edit Financial Calendar"
                                visible={visible}
                                width={600}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="back" className="cancel-btn atn-btn" onClick={handleCancel}>
                                        Cancel
                                    </Button>,
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={confirmLoading}
                                        onClick={handleOk}
                                    >
                                        Submit
                                    </Button>,
                                ]}
                            >
                                <Form
                                    {...formItemLayout}
                                    form={form}
                                    name="financialcalendar"

                                    initialValues={{
                                        residence: ['zhejiang', 'hangzhou', 'xihu'],
                                        prefix: '86',
                                    }}
                                    scrollToFirstError
                                >

                                    <Form.Item
                                        name="date"
                                        label="Date"
                                        rules={[
                                            {
                                                type: 'object',
                                                required: true,
                                                message: "Please input date!",

                                            },
                                        ]}
                                    >
                                        <DatePicker format={"YYYY-MM-DD"} />
                                    </Form.Item>

                                    <Form.Item
                                        name="description"
                                        label="Description"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input description!",
                                                whitespace: true,
                                            },
                                        ]}

                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Form>

                            </Modal>
                            <div style={{ marginLeft: "20px" }}>
                                <Button className="clear-frt" size="large" onClick={clearAll}>Clear filters</Button>
                            </div>               </div>

                        <Form component={false}>
                            <Table scroll={{ x: true }}
                                // components={{
                                //     body: {
                                //         cell: EditableCell,
                                //     },
                                // }}
                                onChange={handleChange}
                                bordered
                                dataSource={data}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: cancel,
                                }}
                            >
                            </Table>

                        </Form>
                    </Col></Row>
            </div>
        </>
    )
}

export default FinancialCalendar;