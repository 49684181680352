import React, { useEffect, useState } from "react";
import {
    Button, DatePicker, Form, Image, Input,
    InputNumber,
    Popconfirm, Space, Table, Tabs, Typography,
    Checkbox, Row, Col,
    Select, message
} from "antd";
import {
    AudioOutlined, DeleteOutlined, EditOutlined, CloseOutlined, FileExcelOutlined,
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined
} from "@ant-design/icons";
import AddBrand from "../../AddBrand/AddBrand";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../services";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

const Brands = (props) => {

    // const [brands, setBrands] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const [brandForm] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;
    const [data, setData] = useState([]);
    const [csvData, setCSVData] = useState([]);
    const [tumors, setTumors] = useState([]);

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }


    const setCSVFileData = (response) => {
        const results = response.map(row => (
            {
                Brand: row.brand,
                Region: row.region,
                Country: row.country,
                Study: row.study,
                Indication: row.indication,
                TumorType: row.tumor_name,
                // RegulatoryApproval: row.regulatory_approval,
                // CommercialLaunch: row.commercial_launch,
                // ReimbursementApproval: row.reimbursement_approval
            }
        ));

        setCSVData(results);
    }

    function getBrandsList() {
        var url = "/launchtracker/api/v1/brand/list"
        GetAPIRequest({
            url: url
        })
            .then(response => {
                if (response) {
                    const results = response.map(row => (
                        {
                            key: row.brand_id,
                            brand_id: row.brand_id,
                            brand: row.brand,
                            region: row.region,
                            country: row.country,
                            study: row.study,
                            indication: row.indication,
                            tumor_name: row.tumor_name,
                            tumor_type_id: row.tumor_type_id
                            // regulatory_approval: moment(row.regulatory_approval).format("YYYY-MM-DD"),
                            // commercial_launch: moment(row.commercial_launch).format("YYYY-MM-DD"),
                            // reimbursement_approval: moment(row.reimbursement_approval).format("YYYY-MM-DD"),
                            // ...row
                        }
                    ));
                    setData(results);
                    setCSVFileData(results);
                }
            })
    }

    useEffect(() => {
        getBrandsList();
        getAllFilters()
        //getBrandByName();
    }, []);


    function getAllFilters() {
        var url = `/launchtracker/api/v1/master/lookup/?type=tumor&type=years&type=country`;
        GetAPIRequest({
            url: url
        }).then(response => {
            response.length > 0 && response.map((item, index) => {
                if (item.type == "tumor")
                    setTumors(response[index].data);

            })

        })
    }

    /*Table code*/

    const cancel = (key) => {

        setEditingKey("");
    };

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        var inputNode = inputType === "number" ? <InputNumber /> : <Input />;
        if (inputType == "select") {
            inputNode = <Select onChange={setTumorIdOnChange}>
                {
                    tumors.length > 0 &&
                    tumors.map((item, index) => {
                        return (
                            <option key={index} value={item.tumor_type_id}> {item.tumor_name}</option>
                        )
                    })
                }
            </Select>
        }
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: inputType === "date" ? false : true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };


    const columns = [
        {
            title: "Brand",
            dataIndex: "brand",
            inputType: "text",
            key: "brand",
            editable: true,
            filters: [
                { text: 'LYNPARZA', value: 'LYNPARZA' },
                { text: 'Imfinzi', value: 'Imfinzi' },
            ],
            filteredValue: filteredInfo.brand || null,
            onFilter: (value, record) => record.brand.includes(value),
            //  sorter: (a, b) => a.brand.length - b.brand.length,
            sorter: (a, b) => a.brand.localeCompare(b.brand),
            sortOrder: sortedInfo.columnKey === "brand" && sortedInfo.order,
            ellipsis: true,
        },
        // {
        //     title: "Region",
        //     dataIndex: "region",
        //     inputType: "text",
        //     key: "region",
        //     editable: true,
        //     filters: [],
        //     filteredValue: filteredInfo.region || null,
        //     onFilter: (value, record) => record.region.includes(value),
        //     sorter: (a, b) => a.region.length - b.region.length,
        //     sortOrder: sortedInfo.columnKey === "region" && sortedInfo.order,
        //     ellipsis: true,
        // },
        // {
        //     title: "Country",
        //     dataIndex: "country",
        //     inputType: "text",
        //     key: "country",
        //     filters: [],
        //     filteredValue: filteredInfo.country || null,
        //     onFilter: (value, record) => record.country.includes(value),
        //     sorter: (a, b) => a.country.length - b.country.length,
        //     sortOrder: sortedInfo.columnKey === "country" && sortedInfo.order,
        //     editable: true,
        // },
        {
            title: "Study",
            dataIndex: "study",
            inputType: "text",
            key: "study",
            filters: [

            ],
            filteredValue: filteredInfo.study || null,
            onFilter: (value, record) => record.study.includes(value),
            //sorter: (a, b) => a.study.length - b.study.length,
            sorter: (a, b) => a.study.localeCompare(b.study),
            sortOrder: sortedInfo.columnKey === "study" && sortedInfo.order,
            editable: true,
        },
        {
            title: "Indication",
            dataIndex: "indication",
            inputType: "text",
            key: "indication",
            filters: [],
            filteredValue: filteredInfo.indication || null,
            onFilter: (value, record) => record.indication.includes(value),
            //sorter: (a, b) => a.indication.length - b.indication.length,
            sorter: (a, b) => a.indication.localeCompare(b.indication),
            sortOrder: sortedInfo.columnKey === "indication" && sortedInfo.order,
            editable: true,
        },
        {
            title: "Tumor Type",
            dataIndex: "tumor_name",
            inputType: "select",
            key: "tumor_name",
            filters: [],
            filteredValue: filteredInfo.tumor_name || null,
            onFilter: (value, record) => record.tumor_name.includes(value),
            // sorter: (a, b) => a.tumor_name.length - b.tumor_name.length,
            sorter: (a, b) => a.tumor_name.localeCompare(b.tumor_name),
            sortOrder: sortedInfo.columnKey === "tumor_name" && sortedInfo.order,
            editable: true,
        },
        {
            title: "",
            width: 80,
            dataIndex: "action",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div className="edit-items">
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 12,
                                padding: "5px 12px",
                                backgroundColor: " #830051",
                                color: "#fff"
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
                            <a className="cancel-btn ant-btn">Cancel</a>
                        </Popconfirm>
                    </div>
                ) : (
                    <>
                        {<div className="edit-ctn">
                            <Space>
                                <Typography.Link
                                    disabled={editingKey !== ""}
                                    onClick={() => edit(record)}
                                >
                                    <EditOutlined /> Edit
                                </Typography.Link>
                            </Space> </div>
                        }
                    </>
                );
            },
        },
        {
            title: '',
            width: 50,
            dataIndex: 'operation',
            render: (_, record) => {

                return (
                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                        handleDelete(record.key)
                    }}>
                        <div className="delete-ctn">
                            <DeleteOutlined /></div>
                    </Popconfirm>
                )
            }
        },
    ];



    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });


    function handleDelete(key) {
        var url = '/launchtracker/api/v1/brand/' + key
        DeleteAPIRequest({
            url: url
        }).then((response) => {
            getBrandsList();
            message.success("Successfully Deleted")
        }).catch((error) => {
            message.error("Something went wrong. Please try again later.")
        });
    }

    function updateBrand(row) {
        var url = "/launchtracker/api/v1/brand/save";
        PostAPIRequest({
            url: url,
            data: row
        })
            .then(response => {
                message.success("Successfully updated")
            }).catch(err => {
                message.error("Something went wrong. Please try again later.")
            })
    }

    const save = async (key) => {
        try {
            const row = await brandForm.validateFields();
            row.brand_id = key;

            let filterval = tumors.filter(x => x.tumor_type_id == tumorId)[0];
            row.tumor_name = filterval.tumor_name;
            row.tumor_type_id = filterval.tumor_type_id;
            updateBrand(row);
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const [tumorId, setTumorId] = useState();

    const setTumorIdOnChange = (e) => {
        setTumorId(e);
    }

    const edit = (record) => {
        setTumorId(record.tumor_type_id);
        brandForm.setFieldsValue({
            ...record,
        });

        setEditingKey(record.key);
    };
    /*ends*/

    return (
        <>
            <div className="admin-table-view-bands">
                <Row>
                    <Col span={24}>
                        <div className="flex-container">
                            <div>

                                <Space style={{ marginBottom: 16 }}>


                                    <AddBrand brands={data} tumors={tumors} callback={getBrandsList} />
                                    {
                                        csvData.length > 0 && <Button className="ant-btn ant-btn-lg downloadcsv">
                                            <CSVLink data={csvData} target="_blank" filename="Brands" >


                                                <FileExcelOutlined />      Export
                                            </CSVLink >
                                        </Button>
                                    }
                                </Space>

                            </div></div>
                        <Form form={brandForm} component={false} >
                            <Table scroll={{ x: true }}
                                // rowSelection={{
                                //   type: selectionType,
                                //   ...rowSelection,
                                // }}

                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                onChange={handleChange}
                                bordered
                                dataSource={data}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: cancel,
                                }}
                            />
                        </Form>

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Brands;