import React from "react";
import { FooterWrapper } from "./Footer.styles";
import Layout, { Footer } from "antd/lib/layout/layout";
import { Col, Row, Image } from "antd";
import AZFTLOGO from "../../assets/img/footer-logo.png";

const AZFooter = (props) => (
  <FooterWrapper>
    <Footer className="footer">
      <Layout>
      <Row>
        <Col span={12}>
        <Image className="AZLogo"  height={20} preview={false}  src={AZFTLOGO}  />
          </Col>
    <Col span={12} style={{textAlign:"right"}}>&copy; Astrazeneca 2022 </Col>
    </Row>
    </Layout>
    </Footer>
  </FooterWrapper>
);

export default AZFooter;
