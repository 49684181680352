import styled from 'styled-components';

export const FooterWrapper = styled.div`
 .footer {  
padding: 18px 20px;
font-size: 0.625rem;
background: rgb(0, 0, 0);
color: rgb(255, 255, 255);
text-align: left;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: justify;
justify-content: space-between;
width:100%
  }
  .ant-layout {
    background-color:transparent;
  }
`;
