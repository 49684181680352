const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const API_BASE_PING_URL = process.env.REACT_APP_BASE_LOGIN_URL;
const appDefaults = {
  api: {
    baseURL: API_BASE_URL,
    loginUrl: API_BASE_PING_URL,
    auth: API_BASE_PING_URL + "/identity/ping/login",
  },
};
export default appDefaults;

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ITEM_CLASS = {
  animation: ["gif", "swf"],
  audio: ["mp1", "mp2", "mp3", "wav"],
  images: ["bmp", "jpeg", "jpg", "png"],
  mixed: ["docx", "doc", "pdf", "rtf", "ppt", "pptx", "csv", "txt"],
  video: ["3gp", "3g2", "mp4", "mpe", "mpeg"],
};
