import React, { useEffect, useState } from "react";
import {
    Button, DatePicker, Form, Image, Input,
    InputNumber,
    Popconfirm, Space, Table, Tabs, Typography,
    Checkbox,
    Select, message
} from "antd";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../services";
import AddRegion from "../Region/AddRegion";
import {
    DeleteOutlined, EditOutlined, DownloadOutlined, FileExcelOutlined
} from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";

const Region = (props) => {

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    //const [isSearch, setIsSearch] = useState(false);
    const [regionForm] = Form.useForm();
    const [data, setData] = useState([]);
    const [tab, setTab] = useState([]);
    const [csvData, setCSVData] = useState([]);

    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;


    function getRegions() {
        var url = "/launchtracker/api/v1/master/region";
        GetAPIRequest({
            url: url
        })
            .then(response => {
                setRegions(response);

            })
    }

    function getCountryList() {
        var url = "/launchtracker/api/v1/master/country/list";
        GetAPIRequest({
            url: url
        })
            .then(response => {
                const results = response.map(item => (
                    {
                        key: item.country_id,
                        region_id: item.region_id,
                        region_name: item.region_name,
                        country_name: item.country_name
                    })
                );
                results.sort(function (a, b) {
                    return a.country_id - b.country_id
                });
                setData(results);
                setCSVFileData(results);
                console.log("coutries list", results)
            })
    }

    const setCSVFileData = (response) => {
        const results = response.map(item => (
            {
                RegionName: item.region_name,
                CountryName: item.country_name
            })
        );
        setCSVData(results);
    }

    function getCountries() {
        var url = `/launchtracker/api/v1/master/lookup/?type=tumor&type=years&type=country`;
        GetAPIRequest({
            url: url
        }).then(response => {
            response.length > 0 && response.map((item, index) => {
                if (item.type == "country")
                    setCountries(response[index].data)
            })
        })
    }

    useEffect(() => {
        getRegions();
        getCountryList();
        getCountries()
    }, []);


    /*Table code*/

    const cancel = (key) => {

        setEditingKey("");
    };

    const [regionId, setRegionId] = useState();

    const setRegionIdOnChange = (e) => {
        setRegionId(e);
    }


    const edit = (record) => {
     
        setRegionId(record.region_id);
        regionForm.setFieldsValue({
            ...record,
        });

        setEditingKey(record.key);
    };

    function handleDelete(key) {
        var url = "/launchtracker/api/v1/master/country/" + key;
        DeleteAPIRequest({
            url: url
        })
            .then(response => {
                message.success("Successfully Deleted");
                getCountryList();
               
            }).catch((error) => {
                message.error("Something went wrong. Please try again later.")
              });
    }

    const updateRegion = (row) => {

        var obj = {
            country_id: row.country_id,
            country_name: row.country_name,
            region_id: row.region_id
        }

        var url = "/launchtracker/api/v1/master/country/save";
        PostAPIRequest({
            url: url,
            data: obj
        })
            .then(response => {
                getCountryList();
                message.success("Successfully updated")
            }).catch(err => {
                message.error("Something went wrong. Please try again later.")
            })
    }

    const save = async (key) => {
        try {
            const row = await regionForm.validateFields();
           
            row.country_id = key;
            let filterval = regions.filter(x => x.region_id == regionId)[0];

            row.region_id = filterval.region_id;
            row.region_name = filterval.region_name;
            updateRegion(row);
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };
    const columns = [
        {

            title: "Region",
            dataIndex: "region_name",
            inputType: "select",
            key: "region_name",
            editable: true,
            filters: regions.map((item, index) => {
                return {
                    text: item.region_name,
                    value: item.region_name
                }
            }),
            filteredValue: filteredInfo.region_name || null,
            onFilter: (value, record) => record.region_name.includes(value),
           // sorter: (a, b) => a.region_name.length - b.region_name.length,
            sorter: (a, b) => a.region_name.localeCompare(b.region_name),
            sortOrder: sortedInfo.columnKey === "region_name" && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: "Country",
            dataIndex: "country_name",
            inputType: "text",
            key: "country_name",
            filters: countries.map((item, index) => {
                return {
                    text: item.country_name,
                    value: item.country_name
                }
            }),
            filteredValue: filteredInfo.country_name || null,
            onFilter: (value, record) => record.country_name.includes(value),
            //sorter: (a, b) => a.country_name.length - b.country_name.length,
            sorter: (a, b) => a.country_name.localeCompare(b.country_name),
            sortOrder: sortedInfo.columnKey === "country_name" && sortedInfo.order,
            editable: true,
        },
        {
            title: "",
            width: 80,
            dataIndex: "action",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div className="edit-items">
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 12,
                                padding:"5px 12px",
                                backgroundColor:" #830051",
                                 color: "#fff"
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
                            <a className="cancel-btn ant-btn">Cancel</a>
                        </Popconfirm>
                    </div>
                ) : (
                    <>
                        {
                            <div className="edit-ctn">
                                <Space>
                                    <Typography.Link
                                        disabled={editingKey !== ""}
                                        onClick={() => edit(record)}
                                    >
                                        <EditOutlined /> Edit
                                    </Typography.Link>
                                </Space>
                            </div>
                        }
                    </>
                );
            },
        },
        {
            title: '',
            width: 50,
            dataIndex: 'operation',
            render: (_, record) => {

                return (
                    <Popconfirm title="Sure to delete?" onConfirm={() => {
                        handleDelete(record.key)
                    }}>  <div className="delete-ctn">
                            <DeleteOutlined /></div>
                    </Popconfirm>
                )
            }
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    const clearFilters = () => {
        setFilteredInfo({});
    };



    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        var inputNode = inputType === "number" ? <InputNumber /> : <Input />;
        if (inputType == "select") {
            inputNode = <Select name={dataIndex} onChange={setRegionIdOnChange}>
                {
                    regions.length > 0 &&
                    regions.map((item, index) => {
                        return (
                            <option key={index} value={item.region_id}> {item.region_name}</option>
                        )
                    })
                }
            </Select>
        }
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: inputType === "date" ? false : true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };
    const clearAll = () => {
        setSortedInfo({});
        setFilteredInfo({});
    };

    function getRegionByName(name) {

    }
    function callback(key) {
        key = key == undefined ? tab : key;
        var selectedRegion = region.filter(x => x.region == key)[0];
        setRegion(selectedRegion)
        setEditingKey("");
        getRegionByName(key);
        setTab(key)
        console.log(key);
        getCountryList();
    }
    return (
        <>
            <div className="region-container admin-table-view">
                <div className="flex-container" style={{ position: "relative" }}>

                    <Space style={{ marginBottom: 16 }}>
                      
                        <AddRegion callback={callback} region={region} />
                        {
                        csvData.length > 0 && <Button className="ant-btn ant-btn-lg downloadcsv">
                            <CSVLink data={csvData} target="_blank" filename="Regions" >
                            <FileExcelOutlined />   Export        
                            
                                                </CSVLink >
                        </Button>
                    }
               
                    </Space>
                    <Button size="large" className="clear-frt" onClick={clearAll}>Clear filters</Button>
                </div>

                <Form form={regionForm} component={false}>
                    <Table scroll={{ x: true }}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        onChange={handleChange}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    >
                    </Table>
                    </Form>
            </div>
        </>
    )
}

export default Region;