import styled from "styled-components";

export const HeaderWrapper = styled.div`
  .header {
    display: flex;
    background-color: #ffffff;
    border-bottom: 1px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding:0px
  }
`;
