import { Layout, Row, Col, } from "antd";
import React from "react";
import Brand from "../Brand";
import UserInfo from "../UserInfo";
import { HeaderWrapper } from "./Header.styles";
const { Header } = Layout;
const AZHeader = (props) => {
  return (
    <HeaderWrapper>
      <Header className="header">
        <Layout style={{backgroundColor:"transparent"}}>
          <Row>
            <Col span={12}>
        <Brand /></Col>
        <Col span={12}>
        <UserInfo /></Col>
        </Row>
        </Layout>
      </Header>
    </HeaderWrapper>
  );
};

export default AZHeader;
