import { Breadcrumb, Layout } from "antd";
import { useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./containers/Home/Home";
import Admin from "./containers/Admin/Admin";
import { getCookie, isAuthenticated, redirectToPing } from "./utils/cookie-helper";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../src/services";
import {
  BrowserRouter as Router,
  Route, Switch, Redirect, Link
} from "react-router-dom";
import Brands from "./containers/Brands";
import { Roles } from './containers/helpers/Roles'
import Authorization from "./containers/Authorization";

const { Content } = Layout;

const App = () => {
  const [isValidUser, setIsValidUser] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(false);
  const [role, setUserRole] = useState(false);
  useEffect(() => {
    sessionStorage.setItem("SSNAPPLOAD", true);
    if (isAuthenticated()) {
      getUserDetails();

    } else {
      redirectToPing();
    }
  }, []);


  function getUserDetails() {
    var url = `/launchtracker/api/v1/user/${getCookie("sub")}`
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setIsValidUser(true);
        if (response) {
          sessionStorage.setItem("userId", response.user_id);
          if (response.role_id == 1) {
            setUserRole("SUPERADMIN")
          }
          else if (response.role_id == 2)
            setUserRole("ADMIN")
          else
            setUserRole("USER")
        }
      }).catch(response => {
        if (response.status == 401) {
          setIsAuthUser(true);
        }
      })
  }


  const PrivateRoute = ({ component: Component, roles, ...rest }) => (

    <Route
      {...rest}
      render={(props) => {
        console.log(roles)
        // check if route is restricted by role
        if (roles && roles.indexOf(role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }

        return <Component {...props} />;
        // return isAuthenticated() ? (
        //   <Component {...props} />
        // ) : (
        //   redirectToPing()
        // )

      }}
    />
  );


  return (
    <>

      < Router >
        {isValidUser && (<Layout className="layout">
          <Header />
          <Content
            style={{ marginTop: "64px", marginBottom: "52px" }}
          >
            <Breadcrumb style={{ margin: "0px 0" }}>
              <Breadcrumb.Item></Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
              {/* <Home /> */}

              {/* <Link to="/" className="nav-item nav-link">Home</Link>
                <Link to="/admin" className="nav-item nav-link">Admin</Link> */}
              <div>
                <Switch>
                  <Route exact path="/" component={Brands} />
                  <PrivateRoute exact path="/home/year/:year" component={Home} />
                  <PrivateRoute exact path="/home/brand/:name/:brandname" component={Home} />
                  <PrivateRoute exact path="/home/tumor/:tumorid/:tumorname" component={Home} />
                  <PrivateRoute exact path="/home/rdfid/:rdfidname" component={Home} />
                  <PrivateRoute exact path="/admin" roles={[Roles.SuperAdmin]} component={Admin} />

                </Switch>
              </div>

            </div>
          </Content>
          <Footer />
        </Layout>
        )}
        {isAuthUser && <Authorization />}
      </Router>

    </>
  );
};

export default App;

