import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Modal, Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker, message
} from "antd";
import React, { useEffect } from "react";
import UsersNewItem from "../UsersNewItem/UsersNewItem";
import { forwardRef, useState, useImperativeHandle } from "react";

import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../../services";



const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};


const AddUsers =  (props) => {
  
  const [visible, setVisible] = React.useState(props.isOpen);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();


  const [Users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);

  function getUsers() {
    var url = "/launchtracker/api/v1/master/users";
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setUsers(response);
      })
  }
  // useImperativeHandle(ref, () => ({
  //   showModalParent() {
  //     showModal()
  //   }
  // }));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => { 
    const row = await form.validateFields();
    console.log("Received values of form: ", row);

    var url = "master/users/save";
    PostAPIRequest({
      url: url,
      data: row,
    }).then((response) => {
      message.success("Successfully created")
      setConfirmLoading(true);
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 1000);
      props.callback();
    }).catch((error) => {
      message.error("Something went wrong. Please try again later.")
    });
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
    form.resetFields();
  };



  const [selectedBrands, setSelectedBrands] = useState([""])
  function setFormField() {
 
    var arr = [];
    props.record.length > 0 && props.record.brands.map((item, index) => {
      arr.push(item.brand_id);
    });
    setSelectedBrands(arr);
    console.log("selectedBrands", arr)

    form.setFieldsValue({
      user: props.record.key,
      brands: selectedBrands,
      PRID: props.record.prid_id,
      email: props.record.email
    });
  }


  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  function getBrandsList() {
    var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
    GetAPIRequest({
      url: url
    })
      .then(response => {
        if (response) {
          const _response = response.sort(GetSortOrder("brand"));
          console.log("_response", _response);
          setBrands(_response);
        }
      })
  }

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const children = [];
  brands && brands.map((item, index) => {
    children.push(<Option key={item.brand} value={item.brand_id}>{item.brand}</Option>);
  });

  useEffect(() => {
    getUsers();
    getBrandsList();
    setFormField()
  }, []);

  return (
    <>
      <Button size="large" type="primary" onClick={showModal}>
        <PlusCircleOutlined />
        Add User
      </Button>
      <Modal
        bodyStyle={{ overflowX: "auto", maxHeight: "calc(80vh - 200px)" }}
        title="Add User"
        visible={visible}
        width={600}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="cancel-btn atn-btn" onClick={handleCancel}>
            Cancel 
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        {/* <UsersNewItem handleCancel={handleCancel} form={form} Users={props.Users} record={props.record} />
       */}
        <Form
          {...formItemLayout}
          form={form}
          name="user"

          initialValues={{
            residence: ['zhejiang', 'hangzhou', 'xihu'],
            prefix: '86',
          }}
          scrollToFirstError
        >
          <Form.Item
            name="user"
            label="User"
            rules={[
              {
                required: true,
                message: "Please input User!",

              },
            ]}
          >
            <Select>
              <option value={2}>Brand Admin</option>
              <option value={3}>User</option>
            </Select>
          </Form.Item>

          <Form.Item
            name="PRID"
            label="PRID"
            rules={[
              {
                required: true,
                message: "Please input PRID!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="brands"
            label="Brands"
            rules={[
              {
                required: true,
                message: "Please input brands!",
              },
            ]}
          >
            <Select mode="multiple" value={selectedBrands}>
              {children}
            </Select>
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input email!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>

      </Modal>
    </>
  );
};

export default AddUsers;
