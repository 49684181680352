import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../utils/cookie-helper";
import {
  User,
  UserInfoDesignation,
  UserInfoName,
  UserInfoWrapper,
} from "./UserInfo.styles";
import { Menu } from 'antd';
import {
  BrowserRouter as Router,
  Route, Switch, Redirect, Link
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../services";
// export const history = createBrowserHistory();
const { SubMenu } = Menu;

const UserInfo = (props) => {
  const [username, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  const [roleId, setUserId] = useState("");
  
  useEffect(() => {
    const user = getCookie("userName");
    const jobTitle = getCookie("jobTitle");
    if (user) {
      setUserName(user);
    }
    if (jobTitle) {
      setDesignation(jobTitle);
    }
    getUserDetails();
  }, []);

  let history = useHistory()
  function redirectToAdmin() {
    history.push('/admin')
  }
  function redirectToHome() {
    history.push('/')
  }

  function getUserDetails() {
    var url = `/launchtracker/api/v1/user/${getCookie("sub")}`
    GetAPIRequest({
      url: url
    })
      .then(response => {
        if (response) {
          setUserId(response.role_id)
        }
      })
  }

  return (
    <UserInfoWrapper className="userinfo-ctn">
      <User>
        <UserInfoName>{username}</UserInfoName>
        <UserInfoDesignation>{designation}</UserInfoDesignation>
      </User>

      <Menu mode="horizontal">

        <SubMenu  key="SubMenu" icon={<Avatar className="userInfoIcon" size="large" icon={<UserOutlined  />} style={{backgroundColor:"rgb(132,1,81) "}} />}>
          <Router>
            {roleId == 1 &&
              < Menu.Item key="setting:1" onClick={() => redirectToAdmin()}>
                Admin
              </Menu.Item>
            }
            {/* <Menu.Item key="setting:2" onClick={() => redirectToHome()}>
              Home
            </Menu.Item> */}
          </Router>
        </SubMenu>
      </Menu>
      
    </UserInfoWrapper >
  );
};

export default UserInfo;
