import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker
} from 'antd';
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../../services";
import moment from "moment";
const { Option } = Select;


const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};


const UsersNewItem = (props) => {

  const [Users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);

  function getUsers() {
    var url = "/launchtracker/api/v1/master/users";
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setUsers(response);
      })
  }
  useEffect(() => {
    getUsers();
    getBrandsList();
    setFormField()
  }, []);

  const [selectedBrands, setSelectedBrands] = useState([""])
  function setFormField() {


    var arr = [];
    props.record.brands.map((item, index) => {
      arr.push(item.brand_id);
    });
    setSelectedBrands(arr);
    console.log("selectedBrands", arr)

    props.form.setFieldsValue({
      user: props.record.key,
      brands: selectedBrands,
      PRID: props.record.prid_id,
      email: props.record.email
    });
  }


  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  function getBrandsList() {
    var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
    GetAPIRequest({
      url: url
    })
      .then(response => {
        if (response) {
          const _response = response.sort(GetSortOrder("brand"));
          console.log("_response", _response);
          setBrands(_response);
        }
      })
  }

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const children = [];
  brands && brands.map((item, index) => {
    children.push(<Option key={item.brand} value={item.brand_id}>{item.brand}</Option>);
  });

  return (
    selectedBrands && <Form
      {...formItemLayout}
      form={props.form}
      name="user"

      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
      <Form.Item
        name="user"
        label="User"
        rules={[
          {
            required: true,
            message: "Please input User!",

          },
        ]}
      >
        <Select>
          <option value={2}>Brand Admin</option>
          <option value={3}>User</option>
        </Select>
      </Form.Item>

      <Form.Item
        name="PRID"
        label="PRID"
        rules={[
          {
            required: true,
            message: "Please input PRID!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="brands"
        label="Brands"
        rules={[
          {
            required: true,
            message: "Please input brands!",
          },
        ]}
      >
        <Select mode="multiple" value={selectedBrands}>
          {children}
        </Select>
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: "Please input email!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
export default UsersNewItem;
