import { Input, Form, Select, Button, Space, Row, Col, Tabs, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import {    CaretLeftOutlined   } from "@ant-design/icons";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../services";
import { Menu, Layout, notification } from 'antd';
import BrandList from '../Admin/BrandList';
import Users from './Users';
import Region from './Region';
import FinancialCalendar from './FinancialCalendar';
import styled from './Admin.styles'
import { Redirect, useHistory } from 'react-router-dom';
import { getCookie, isAuthenticated, redirectToPing } from "../../utils/cookie-helper";

const { Header, Content, Sider } = Layout;

const { TabPane } = Tabs;



const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};


const openNotification = placement => {
    notification.info({
        message: `Notification`,
        description: 'Successfully created',
        placement,
    });
};

const Admin = (props) => {

    const [brands, setBrands] = useState([]);
    const [countries, setCountries] = useState([]);
    const [tumors, setTumors] = useState([]);
    const history = useHistory();
   /* const goBack = (props) => {
        
                  debugger;
        const { pathname } = window.location;
        const historyp = history.goBack();
        if(pathname == historyp)
        { history.goBack(-1)}
        else history.goBack()
      }*/
    useEffect(() => {
        getBrandsList();
        getAllFilters();
    }, [])



    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    function getBrandsList() {
        var url = "/launchtracker/api/v1/brand/list"
        GetAPIRequest({
            url: url
        })
            .then(response => {
                if (response) {
                    const _response = response.sort(GetSortOrder("brand"));
                    console.log("_response", _response);
                    setBrands(_response);

                }
            })
    }

    function getAllFilters() {
        var url = `/launchtracker/api/v1/master/lookup/?type=tumor&type=years&type=country`;
        GetAPIRequest({
            url: url
        }).then(response => {
            response.length > 0 && response.map((item, index) => {
                if (item.type == "tumor")
                    setTumors(response[index].data);
                else if (item.type == "country")
                    setCountries(response[index].data)
            })

        })
    }

    const [adminForm] = Form.useForm();

    const onFinish = (values) => {
        var url = "/launchtracker/api/v1/user/save"
        console.log('Success:', values);
        PostAPIRequest({
            url: url,
            data: values
        }).then(response => {
            notification.info({
                message: `Notification`,
                description: 'Successfully created',
                placement: 'topRight',
            })
            adminForm.resetFields()
        })
    };

    const showAlert = () => {
        // notification.info({
        //     message: `Notification`,
        //     description: 'Cancelled',
        //     placement: 'topRight',
        // });
    }
    function callback(key) {
        console.log(key);
    }


    return (
        < >
            <Content>
            <Tooltip className='back-btn-tooltip' placement="left" title={"back to previous page"}>
            <button className='fixedbutton' onClick={history.goBack} style={{marginTop:15}}><CaretLeftOutlined /> </button>
                    </Tooltip> 
                <Row justify="center">
                    <Col span={22}>
                    <button className='backbtn' onClick={history.goBack} style={{marginTop:15}}><CaretLeftOutlined /> Back</button>
                        <Tabs defaultActiveKey="1" onChange={callback} type="card">
                            <TabPane tab="Brands" key="1">
                               <div className='tab-inner-content'> <BrandList /></div>
                            </TabPane>
                            <TabPane tab="Users" key="2">
                            <div className='tab-inner-content'>  <Users /></div>
                            </TabPane>
                            <TabPane tab="Region" key="3">
                            <div className='tab-inner-content'> <Region /></div>
                            </TabPane>
                            <TabPane tab="FinancialCalendar" key="4">
                            <div className='tab-inner-content'> <FinancialCalendar /></div>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Content>


        </>
        // <Row style={{

        //     marginTop: "30px",

        // }}>

        //     <Col span={12} offset={6}>
        //         <Form className='adminForm'
        //             {...formItemLayout}
        //             form={adminForm}
        //             name="brand"
        //             labelCol={{
        //                 span: 8,
        //             }}
        //             wrapperCol={{
        //                 span: 16,
        //             }}
        //             initialValues={{
        //                 remember: true,
        //             }}
        //             onFinish={onFinish}
        //         >
        //             <Form.Item name="prid_id"
        //                 label="User Id"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         message: "Please input User Id!"
        //                     },
        //                 ]} >
        //                 <Input />
        //             </Form.Item>
        //             <Form.Item name="email"
        //                 label="Email Id"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         type: "email",
        //                         message: "Please input Email Id!"
        //                     },
        //                 ]} >
        //                 <Input />
        //             </Form.Item>
        //             <Form.Item name="brands"
        //                 label="Brands"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         message: "Please input Brands!"
        //                     },
        //                 ]} >
        //                 <Select
        //                     mode="multiple"
        //                     allowClear>
        //                     {
        //                         brands.length > 0 &&
        //                         brands.map((item, index) => {
        //                             return (
        //                                 <option key={index} value={item.id}> {item.brand}</option>
        //                             )
        //                         })
        //                     }
        //                 </Select>
        //             </Form.Item>
        //             <Form.Item name="tumors"
        //                 label="Tumors"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         message: "Please input Tumors!"
        //                     },
        //                 ]} >
        //                 <Select
        //                     mode="multiple"
        //                     allowClear>
        //                     {
        //                         tumors.length > 0 &&
        //                         tumors.map((item, index) => {
        //                             return (
        //                                 <option key={index} value={item.tumor_type_id}> {item.tumor_name}</option>
        //                             )
        //                         })
        //                     }
        //                 </Select>
        //             </Form.Item>
        //             <Form.Item name="countries"
        //                 label="Countries"
        //                 rules={[
        //                     {
        //                         required: true,
        //                         message: "Please input Countries!"
        //                     },
        //                 ]} >
        //                 <Select
        //                     mode="multiple"
        //                     allowClear>
        //                     {
        //                         countries.length > 0 &&
        //                         countries.map((item, index) => {
        //                             return (
        //                                 <option key={index} value={item.country_id}> {item.country_name}</option>
        //                             )
        //                         })
        //                     }
        //                 </Select>
        //             </Form.Item>
        //             <Form.Item {...tailLayout}>

        //                 <Space>
        //                     <Button type="primary" htmlType="submit">
        //                         Submit
        //                     </Button>
        //                     <Button htmlType="button" onClick={() => showAlert()}>Cancel</Button>
        //                 </Space>
        //             </Form.Item>
        //         </Form>
        //     </Col>
        // </Row>
    )
}

export default Admin;