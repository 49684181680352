import axios from "axios";
import constants from "../../constants";
import { getCookie, redirectToPing, getRefreshToken } from "../../utils/cookie-helper";
export const client = axios.create({
  withCredentials: true,
});

// Add a request interceptor
client.interceptors.request.use(
  function (config) {
    config.headers.common["Content-Type"] = "application/json";
    const access_token = getCookie("access-token");
    if (access_token && access_token !== "") {
      config.headers.common["authorization"] = "Bearer " + access_token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * Request Wrapper with default success/error actions
 */
const request = async function (options) { 
  let access_token = getCookie("access-token");
  const refresh_token = getCookie("refresh-token");
  if ((access_token == "" || access_token == undefined) && refresh_token != "" && refresh_token != undefined) {
    var refreshToken = await getRefreshToken();
    if (refreshToken)
      access_token = getCookie("access-token");
  }
  if (access_token) {
    const onSuccess = function (response) {
      console.log("Request Successful!", response);
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
      return response.data;
    };

    const handleStatusCode = (statuscode) => {
      switch (statuscode) {
        case 200:
          console.log("ajax.statusCode: 200");
          break;
        case 404:
          console.log("ajax.statusCode: 404");
          break;
        case 500:
          console.log("ajax.statusCode: 500");
          break;
        case 403:
          console.log("ajax.statusCode: 403");
          localStorage.setItem("isPrevState", "TRUE");
          redirectToPing();
          break;
        default:
          break;
      }
    };

    const onError = function (error) {
      console.error("Request Failed:", error.config);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
        handleStatusCode(error.response.status);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error("Error Message:", error.message);
      }

      return Promise.reject(error.response || error.message);
    };

    return client(options).then(onSuccess).catch(onError);
  }
  else {
    redirectToPing();
  }
};

export const GetAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    withCredentials: false,
    method: "GET",
    ...otherOptions,
  });
};
export const PostAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "POST",
    withCredentials: false,
    body: JSON.stringify(options.data),
    ...otherOptions,
  });
};
export const PutAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "PUT",
    withCredentials: false,
    ...otherOptions,
  });
};
export const DeleteAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "DELETE",
    withCredentials: false,
    ...otherOptions,
  });
};
export const MultipleGetRequest = (options) => {
  if (options && options.URL1 && options.URL2) {
    axios
      .all([axios.get(options.URL1), axios.get(options.URL2)])
      .then((responseArr) => {
        console.log("Request Successful!", responseArr);
        return responseArr;
      })
      .catch((error) => {
        console.error("Request Failed:", error.config);
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Data:", error.response.data);
          console.error("Headers:", error.response.headers);
        } else {
          console.error("Error Message:", error.message);
        }
        return Promise.reject(error.response || error.message);
      });
  }
};

export default request;
