import {
  Tabs,
  Image,
  Row,
  Col,
  Card,
  Select,
  List,
  Divider,
  Button,
  Space,
} from "antd";
import Layout from "antd/lib/layout/layout";
import Item from "antd/lib/list/Item";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import azlogo from "../../logo-az.png";
import calquence from "../../assets/img/calquence.png";
import enhertu from "../../assets/img/enhertu.png";
import imfinzi from "../../assets/img/imfinzi.png";
import tagrisso from "../../assets/img/tagrisso.png";
import lynparza from "../../assets/img/lynparza.png";
import {
  DeleteAPIRequest,
  GetAPIRequest,
  PostAPIRequest,
} from "../../services";
const TabPane = Tabs;

const { Option } = Select;
const Brands = (props) => {
  const [brands, setBrands] = useState([]);
  const [earlyBrands, setEarlyBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState([]);
  const [tumor, setTumor] = useState([]);
  const [rdfid, setRdfid] = useState([]);
  const [tumorName, setTumorName] = useState([]);
  const history = useHistory();
  const brandLogos = {
    calquence: calquence,
    enhertu: enhertu,
    imfinzi: imfinzi,
    tagrisso: tagrisso,
    lynparza: lynparza,
  };
  useEffect(() => {
    getBrandsList();
    getAllFilters();
  }, []);

  function getAllFilters() {
    var url = `/launchtracker/api/v1/master/lookup/?type=tumor&type=years&type=country&type=rdfid`;
    GetAPIRequest({
      url: url,
    }).then((response) => {
      response.length > 0 &&
        response.map((item, index) => {
          if (item.type == "years") setYears(response[index].data.reverse());
          if (item.type == "tumor") setTumor(response[index].data.reverse());
          if (item.type == "rdfid") setRdfid(response[index].data.reverse());
        });
    });
  }

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  function getBrandsList() {
    var url = `/launchtracker/api/v1/brand/v1/list/${sessionStorage.getItem('userId')}`;
    GetAPIRequest({
      url: url,
    }).then((response) => {

      let trending = []
      let early = [];
      if (response) {
        const _response = response.sort(GetSortOrder("brand"));
        console.log("_response", _response);
        _response.map((item, index) => {
          if (item.is_trending) {
            trending.push(item)
          }
          else {
            early.push(item)
          }
        })
        setBrands(trending);
        setEarlyBrands(early);
      }
    });
  }

  const callback = (key) => { };

  const gotoHomeById = (id) => {
    let brandname = brands.filter(x => x.brand_id == id)[0].brand;
 
    history.push("/home/brand/" + id + "/" + brandname);
  };
  const gotoHomeByYear = (year) => {

    history.push("/home/year/" + year);
  };
  const gotoHomeByTumor = (tumor_name) => {

    let tumorid = tumor.filter(x => x.tumor_name.toUpperCase() == tumor_name)[0].tumor_type_id
    history.push("/home/tumor/" + tumorid + "/" + tumor_name);
  };

  const gotoHomeByRdfid = (rdfId) => {

    //let study = rdfid.filter(x => x.rdfid == rdfId)[0].study
    history.push("/home/rdfid/" + rdfId);
  };

  const gotoHomeByBrand = (id) => {
    let brandname = earlyBrands.filter(x => x.brand_id == id)[0].brand;
    history.push("/home/brand/" + id + "/" + brandname);
  }

  return (
    <Layout>
      <div className="home-content" style={{ margin: "24px 24px 44px" }}>
        <div className="brandtitle"
          style={{
            textAlign: "left",
            padding: "15px 20px 15px",
            fontWeight: "500",
            fontSize: "20px",
            cursor: "pointer",
            color: "#fff",
            borderBottom: "1px solid rgb(255, 153, 0)",
            marginBottom: "25px",

          }}
        >
          Select Brand
        </div>
        <div className="site-card-wrapper" style={{ padding: "24px" }}>
          <List
            grid={{
              gutter: 10, xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 3,
            }}
            dataSource={brands}
            renderItem={(brand) => (
              <List.Item>
                <Card
                  hoverable
                  style={{
                    textAlign: "center",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    minHeight: 130,
                    maxHeight: 130,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: 10,
                    cursor: "pointer",
                    color: "#666666",
                    border: "2px solid #ff9900"
                  }}
                  onClick={() => gotoHomeById(brand.brand_id)}
                >
                  {brand &&
                    brand.brand &&
                    brandLogos[brand.brand.toLowerCase()] ? (
                    <Image
                      style={{ height: 80, objectFit: "contain" }}
                      preview={false}
                      src={brandLogos[brand.brand.toLowerCase()]}
                    />
                  ) : (
                    <span>{brand.brand}</span>
                  )}
                </Card>
              </List.Item>
            )}
          />
        </div>
      </div>
      <Row justify="left">
        <Col span={24}>
          <div justify="left">
            <Divider className="brandTitle" orientation="center" style={{ borderColor: "#969696" }}>
              (OR)
            </Divider>
          </div>
        </Col>
      </Row>
      <div className="otherfilters" style={{ padding: "24px" }}>
        <Row>
          <Col>
            <div style={{ margin: "0px 0px 44px" }}>
              <Select className="ml-2" style={{ marginLeft: "20px", minWidth: "200px" }}

                placeholder="Select a Tumor"
                onChange={gotoHomeByTumor}
              >
                {tumor &&
                  tumor.map((item) => {
                    return (

                      <Option value={(item.tumor_name)}>{item.tumor}</Option>
                    )
                  })}
              </Select>
              <Select
                placeholder="Select a Brand"
                onChange={gotoHomeByBrand}
                style={{ marginLeft: "20px", minWidth: "200px" }}
              >
                {earlyBrands &&
                  earlyBrands.map((item) => {
                    return (
                      <Option value={item.brand_id}>{item.brand}</Option>
                    )
                  })}
              </Select>

              <Select
                placeholder="Select a rdfid"
                onChange={gotoHomeByRdfid}
                style={{ marginLeft: "20px", minWidth: "200px" }}
              >
                {rdfid &&
                  rdfid.map((item) => {
                    return (
                      <Option value={item.study}>{item.rdfid}</Option>
                    )
                  })}
              </Select>
              <Select

                placeholder="Select a year"
                onChange={gotoHomeByYear}
                style={{ marginLeft: "20px", minWidth: "200px" }}
              >
                {years &&
                  years.map((item) => {
                    return (
                      <Option value={item.year_id}>{item.year}</Option>
                    )
                  })}
              </Select>
            </div>
          </Col>
        </Row>

      </div>



    </Layout>
  );
};

export default Brands;
