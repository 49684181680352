import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, message } from "antd";
import React from "react";
import { PostAPIRequest } from "../../../../services";
import RegionNewItem from "../../Region/RegionNewItem";

const AddRegion = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => { 
    const countryValues = await form.validateFields();
    console.log("Received values of form: ", countryValues);

    var url = "/launchtracker/api/v1/master/country/save";
    PostAPIRequest({
      url: url,
      data: countryValues,
    }).then((response) => {
      message.success("Successfully created")
      setConfirmLoading(true);
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 1000);
      form.resetFields();
      props.callback();
    }).catch((error) => {
      message.error("Something went wrong. Please try again later.")
    });
  };

  
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button size="large" type="primary" onClick={showModal}>
        <PlusCircleOutlined />
        Add Region
      </Button>
      <Modal
        bodyStyle={{ overflowX: "auto", maxHeight: "calc(80vh - 200px)" }}
        title="Add Region"
        visible={visible}
        width={600}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} className="cancel-btn atn-btn">
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <RegionNewItem handleCancel={handleCancel} form={form} Region={props.Region} />
      </Modal>
    </>
  );
};

export default AddRegion;
