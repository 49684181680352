
import { Result, Button } from 'antd';

const Authorization = () => {

    return (
        <div>
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page. Please contact admin"

            />
        </div>
    )
}

export default Authorization;