import styled from "styled-components";

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  font-size: 16px;
  margin: 0px 10px 0px;
  flex: 1;
  cursor: pointer;
  font-weight: 400;
 
  .brandDivider{
    height: 40px;
    border-color:rgb(153, 153, 153);
  }
  .ant-image-img{
    vertical-align:top;
  }
`;
