import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker
} from 'antd';
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../../../services";
import moment from "moment";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};


const FinancialCalendarNewItem = (props) => {
 

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));
  return (
    <Form
      {...formItemLayout}
      form={props.form}
      name="financialcalendar"

      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >

      <Form.Item
        name="date"
        label="Date"
        rules={[
          {
            type: 'object',
            required: true,
            message: "Please input date!",

          },
        ]}
      >
        <DatePicker format={"YYYY-MM-DD"} />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: "Please input description!",
            whitespace: true,
          },
        ]}

      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};
export default FinancialCalendarNewItem;
