import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker
} from 'antd';
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "../../services";
import moment from "moment";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};


const NewItemForm = (props) => {

  const brandList = props.brands.length > 0 &&
    props.brands.map((item, index) => {
      return (
        <option key={index} value={item.id}> {item.brand}</option>
      )
    })
  const [regions, setRegions] = useState([]);

  function getRegions() {
    var url = "/launchtracker/api/v1/master/region";
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setRegions(response);
      })
  }
  useEffect(() => {
    getRegions();
  }, []);

  function getCountryById(d) {
    var url = `/launchtracker/api/v1/master/region/${d}/country`;
    GetAPIRequest({
      url: url
    })
      .then(response => {
        setCountries(response);
        props.form.setFieldsValue({
          country: ''
        })
      })
  }

  const [countries, setCountries] = useState([]);

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));
  return (
    <Form
      {...formItemLayout}
      form={props.form}
      name="brand"

      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
      <Form.Item
        name="brand"
        label="Brand"
        rules={[
          {
            required: true,
            message: "Please input brand!"
          },
        ]}
      >
        {/* <Select >
          {brandList}
        </Select> */}
        <Input />
      </Form.Item>
      {/* <Form.Item
        name="region"
        label="Region"
        rules={[
          {
            required: true,
            message: "Please input region!"
          },
        ]}
      >
        <Select onChange={(d) => getCountryById(d)}>
          {
            regions.length > 0 &&
            regions.map((item, index) => {
              return (
                <option key={index} value={item.region_id}> {item.region_name}</option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="country"
        label="Country"
        rules={[
          {
            required: true,
            message: "Please input country!"
          },
        ]}
      >
        <Select >
          {
            countries.length > 0 &&
            countries.map((item, index) => {
              return (
                <option key={index} value={item.country_id}> {item.country_name}</option>
              )
            })}
        </Select>
      </Form.Item> */}
      <Form.Item
        name="study"
        label="Study"
        rules={[
          {
            required: true,
            message: "Please input study!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="indication"
        label="Indication"
        rules={[
          {
            required: true,
            message: "Please input indication!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="tumor_type_id"
        label="Tumor Type"
        rules={[
          {
            required: true,
            message: "Please input tumor type!",
          },
        ]}
      >
        <Select >
          {props.tumors.length > 0 &&
            props.tumors.map((item, index) => {
              return (
                <Option key={index} value={item.tumor_type_id}> {item.tumor_name}</Option>
              )
            })
          }
        </Select>
      </Form.Item>
      {/* <Form.Item
        name="regulatory_approval"
        label="Regulatory Approval"
        rules={[
          {
            type: 'object',
            validator: (value) => {
              const regulatoryDate = props.form.getFieldValue("regulatory_approval");
              const reimbursementDate = props.form.getFieldValue("reimbursement_approval");
              if (regulatoryDate && reimbursementDate) {
                var diff = regulatoryDate.diff(reimbursementDate, 'days', true);
                if (diff < 0) {
                  return Promise.resolve("");
                }
                else {
                  return Promise.reject("Regulatory Approval date should less than Reimbursement Approval!");
                }
              }
              else {
                return Promise.resolve("");
              }
            }
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item name="reimbursement_approval" label="Reimbursement Approval" >
        <DatePicker />
      </Form.Item>
      <Form.Item
        name="commercial_launch"
        label="Commercial Launch"
      // rules={[
      //   {
      //     type: "object",
      //     required: true,
      //     message: "Please input Commercial Approval!",
      //     whitespace: true,
      //   },
      // ]}
      >
        <DatePicker />
      </Form.Item> */}
    </Form>
  );
};
export default NewItemForm;
