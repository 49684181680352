import { Divider, Image } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import AZLOGO from "../../assets/img/az-logo.png";
import { BrandWrapper } from './Brand.styles';

const Brand = () => {
  const history = useHistory();
  const gotoHome = () => {
    history.push("/")
  }
  return (
    <BrandWrapper onClick={() => gotoHome()} className="header-logo">
      <Image className="brandLogo" preview={false} height={35} src={AZLOGO}  />
      <Divider className="brandDivider" type="vertical" />
      <div className="brandName">OBU Commercial Launch Tracker</div>
    </BrandWrapper>
  )
};

export default Brand;
